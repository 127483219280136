import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import IconDownSide from '../svg/IconDownSide';

const Breadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
    let breadcrumbPath = "";

    return (
        <div className='breadcrunbs flex items-center gap-1'>
            {pathnames.length >= 2 ? pathnames.map((name, index) => {
                breadcrumbPath += `/${name}`;
                const isLast = index === pathnames.length - 1;
                const isFirst = index === 0;

                return isLast ? (
                    <span key={breadcrumbPath} className='text-xs text-textV2 font-500 capitalize'>{name}</span>
                ) : isFirst ? (
                    <span key={breadcrumbPath} className='flex items-center gap-1'> <Link to={breadcrumbPath === '/portfolio' ? '/' : breadcrumbPath === '/allocation' ? '/allocation' : '#'} className='text-xs text-theme font-semibold  flex items-center gap-1 capitalize'>{name}</Link> <span><IconDownSide className="text-textV2 w-4 h44 -rotate-90" /></span></span>
                ) : name === "no-portfolio-group" ? <span key={breadcrumbPath} className='flex items-center gap-1'> <span to={breadcrumbPath} className='text-xs text-textV2 font-500 flex items-center gap-1 capitalize'>{name}</span>   <span><IconDownSide className="text-textV2 w-4 h44 -rotate-90" /></span></span> : (
                    <span key={breadcrumbPath}   className='flex items-center gap-1'> <Link to={breadcrumbPath} className='text-xs text-theme font-semibold flex items-center gap-1 capitalize'>{name}</Link>   <span><IconDownSide className="text-textV2 w-4 h44 -rotate-90" /></span></span>


                );
            }) : ''}
        </div>
    )
}

export default Breadcrumbs