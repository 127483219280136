import apiConfig from '../../config/apiConfig';
import { allocationsActionType, apiBaseURL, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { metaDataAction } from './metaDataAction';
import { addTost } from './toastAction';

export const fetchAllocations = (payload = {}) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { currentPage = null, perPage = null, ...restPayload } = payload
        let url = `${apiBaseURL.ALLOCATION}`;

        if (currentPage && perPage && currentPage !== undefined && currentPage !== null && perPage !== undefined && perPage !== null) {
            url += `?page=${currentPage}&perPage=${perPage}`;
        }

        const response = await apiConfig.post(url, restPayload);
        dispatch({ type: allocationsActionType.FETCH_ALLOCATIONS, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage, pageSize: perPage, totalItems: response?.data?.data?.totalRecords }))
        dispatch(setLoading(false))
        // setDataFetched && setDataFetched(true)
        return response
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAllocationsDetails = (id, setAllocationsDetailsData, setDataFetched, setIsLoad) => async (dispatch) => {
    dispatch(setLoading(true))
    setIsLoad && setIsLoad(true)
    try {
        const response = await apiConfig.get(apiBaseURL.ALLOCATION + '/' + id + '/details')
        setAllocationsDetailsData && setAllocationsDetailsData([response.data?.data]);
        setDataFetched && setDataFetched(true)
        dispatch({ type: allocationsActionType.FETCH_ALLOCATIONS_DETAILS, payload: [response.data?.data] });
        dispatch(setLoading(false))
        setIsLoad && setIsLoad(false)
        return response;
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
        dispatch(setLoading(false))
        setIsLoad && setIsLoad(false)
        return error;
    }
}

export const addAllocation = (data, setAllocationData, setDataFetched, currentPage, perPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ALLOCATION + '/add', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, currentPage, perPage, setDataFetched));
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const addAllocate = (data, setAllocationData, setDataFetched) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ALLOCATION + '/assets-allocation', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, setDataFetched))
    } catch (error) {
        dispatch(setLoading(false))
        if (error.response.status === 422) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data.message }))
        } else {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
        }
    }
}

export const authorizeAllocate = (data) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ALLOCATION + '/config', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, currentPage, perPage, setDataFetched))
        return response
    } catch (error) {
        dispatch(setLoading(false))
        if (error.response.status === 422) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data.message }))
        } else {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
        }
    }
}

export const updateAllocationDetails = (data, setAllocationData, setDataFetched, currentPage, perPage) => async (dispatch) => {

    dispatch(setLoading(true))
    try {
        const response = await apiConfig.put(apiBaseURL.ALLOCATION, data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, currentPage, perPage, setDataFetched))
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const cloneAllocation = (payload, id, setAllocationData, setDataFetched, currentPage, perPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ALLOCATION + "/clone-allocation", payload)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, currentPage, perPage, setDataFetched))
        return response
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const deleteAllocation = (data, setAllocationData, setDataFetched, currentPage, perPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ALLOCATION + '/config', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, currentPage, perPage, setDataFetched))
        return response
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const archiveAllocation = (data, setAllocationData, setDataFetched, currentPage, perPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ALLOCATION + '/config', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchAllocations(setAllocationData, currentPage, perPage, setDataFetched))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}