import React from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import LoadAllocation from "../../allocations/LoadAllocation"
import CreateAllocation from "../../allocations/createAllocation"

const OuterTabs = (props) => {
    const {
        allocationTab,
        onTabChange,
        setTargetType,
        selectedPortfolioGroup,
        setSelectedPortfolioGroup,
        selectedPortfolio,
        targetType,
        setSelectedPortfolio,
        createAllocation,
        createAllocationInfo,
        setCreateAllocationInfo,
        createAllocationErrors,
        allPortfolios,
        loadAllocationInfo,
        setLoadAllocationInfo,
        allAllocationsData,
        setAllAllocationsData,
    } = props;
    return (
        <>
            <Tabs defaultValue={allocationTab} value={allocationTab} onValueChange={onTabChange} className="px-8">
                <TabsList className=" p-2  shadow-md" >
                    <TabsTrigger value="Create Allocation" className="px-[15px] py-[10px] data-[state=active]:!bg-theme   data-[state=active]:!text-white">Create Allocation</TabsTrigger>
                    <TabsTrigger value="Load Allocation" className="px-[15px] py-[10px] data-[state=active]:!bg-theme   data-[state=active]:!text-white">Load Allocation</TabsTrigger>
                </TabsList>
                <TabsContent value="Create Allocation">
                    <CreateAllocation
                        setTargetType={setTargetType}
                        selectedPortfolioGroup={selectedPortfolioGroup}
                        setSelectedPortfolioGroup={setSelectedPortfolioGroup}
                        selectedPortfolio={selectedPortfolio}
                        targetType={targetType}
                        setSelectedPortfolio={setSelectedPortfolio}
                        createAllocation={createAllocation}
                        createAllocationInfo={createAllocationInfo}
                        setCreateAllocationInfo={setCreateAllocationInfo}
                        createAllocationErrors={createAllocationErrors}
                        allPortfolios={allPortfolios}
                    />
                </TabsContent>
                <TabsContent value="Load Allocation">
                    <LoadAllocation
                        setTargetType={setTargetType}
                        targetType={targetType}
                        loadAllocationInfo={loadAllocationInfo}
                        setLoadAllocationInfo={setLoadAllocationInfo}
                        allAllocationsData={allAllocationsData}
                        setAllAllocationsData={setAllAllocationsData}
                        allocationTab={allocationTab}
                        allPortfolios={allPortfolios}
                    />
                </TabsContent>
            </Tabs>
        </>
    )
}

export default OuterTabs