import DashboardMaster from '@/layout/dashboard/DashboardMaster'
import React, { useState, useCallback, useEffect } from "react"
import { useDispatch } from 'react-redux'
import { fetchPortfolioGroup, fetchPortfolioGroups } from '@/store/actions/portfolioGroupAction'
import { useSelector } from 'react-redux'
import { addAllocation, fetchAllocations } from '@/store/actions/AllocationAction'
import { fetchAllPortfolios } from '@/store/actions/portfolioAction'
import { useNavigate } from 'react-router-dom'
import OuterTabs from './_components/tabs/outerTabs'

const NewAllocation = () => {
    const [targetType, setTargetType] = useState("Portfolio Group")
    const [selectedPortfolio, setSelectedPortfolio] = useState(null)
    const [selectedPortfolioGroup, setSelectedPortfolioGroup] = useState(null)
    const [createAllocationInfo, setCreateAllocationInfo] = useState({
        name: "",
        description: "",
        startDate: null,
        endDate: null,
        portfolio: null,
        portfolioGroup: null
    });
    const [loadAllocationInfo, setLoadAllocationInfo] = useState({
        portfolio: null,
        portfolioGroup: null,
        loadAllocation: null
    });
    const [createAllocationErrors, setCreateAllocationErrors] = useState({});
    const [allAllocationsData, setAllAllocationsData] = useState([]);
    const [allocationTab, setAllocationTab] = useState('Create Allocation');
    const [allPortfolios, setAllPortfolios] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchPortfolioGroups());
        dispatch(fetchAllPortfolios()).then((response) => {
            if (response?.data?.success) {
                setAllPortfolios(response?.data?.data?.records)
            }
        });
    }, [allocationTab])

    const portfolioGroupDetailWithSlugSelectorValue = useSelector(state => state.PortfolioGroupDetails);

    // Generate options for portfolios based on portfolioSelectorValue
    const portfolioOptions = allPortfolios?.length > 0
        ? allPortfolios.map((item) => {
            return ({
                value: item?.id,
                label: item?.name,
                slug: item?.slug
            })
        })
        : [];

    // Filter portfolios based on matching IDs in portfolioOptions
    const filteredPortfolios = portfolioGroupDetailWithSlugSelectorValue?.length > 0
        ? portfolioGroupDetailWithSlugSelectorValue.filter(item =>
            portfolioOptions.some(option => option?.value === item?.portfolio?._id)
        ) : [targetType];


    // Generate options with slugs for filtered portfolios
    const portfolioGroupOptionsWithSlug = filteredPortfolios.length > 0 ? filteredPortfolios.map((item) => ({
        value: item?.portfolio?._id,
        label: item?.portfolio?.name,
        slug: item?.portfolio?.slug
    }))?.filter((item) => item?.value) : [];

    const portfolioGroupIds = portfolioGroupOptionsWithSlug.map((item) => item?.value);

    const flattenAllocations = useCallback((allocations, prefix = '') => {
        return Object.entries(allocations).flatMap(([key, value]) => {
            const newPrefix = prefix ? `${prefix}.${key}` : key
            const result = [{ name: newPrefix, value }]
            if (value && value.subAllocations) {
                result.push(...flattenAllocations(value.subAllocations, newPrefix))
            }
            return result
        })
    }, [])

    // create Allocation code starts here

    const createValidateAllocation = useCallback(() => {
        let isValid = true
        const error = {}

        if (!createAllocationInfo?.name) {
            isValid = false
            error.name = "Name is required"
        }

        if (createAllocationInfo?.portfolio === null && targetType === "Portfolio") {
            isValid = false
            error.portfolio = "Portfolio is required"
        }

        if (createAllocationInfo?.portfolioGroup === null) {
            isValid = false
            error.portfolioGroup = "Portfolio Group is required"
        }
        if (!targetType === "Portfolio Group" && createAllocationInfo?.portfolio === null) {
            isValid = false
            error.portfolio = "Portfolio is required"
        }

        if (createAllocationInfo?.startDate === null) {
            isValid = false
            error.startDate = "Start Date is required"
        }

        if (createAllocationInfo?.endDate === null) {
            isValid = false
            error.endDate = "End Date is required"
        }

        setCreateAllocationErrors(error);

        return isValid

    }, [createAllocationInfo, portfolioGroupIds, targetType])

    const createAllocation = useCallback(() => {
        if (createValidateAllocation()) {
            const prepareData = {
                name: createAllocationInfo?.name,
                description: createAllocationInfo?.description,
                startDate: createAllocationInfo?.startDate,
                endDate: createAllocationInfo?.endDate,
            }

            if (targetType === "Portfolio") {
                prepareData.portfolio = [createAllocationInfo?.portfolio?.value]
            }

            if (targetType === "Portfolio Group") {
                prepareData.portfolioGroup = createAllocationInfo?.portfolioGroup?.value
            }
            dispatch(addAllocation(prepareData)).then(res => {
                if (res?.data?.success) {
                    setCreateAllocationInfo({
                        name: "",
                        description: "",
                        startDate: null,
                        endDate: null,
                        portfolio: null,
                        portfolioGroup: null
                    })
                    localStorage?.setItem('currentAllocation', JSON.stringify({ value: res?.data?.data?._id, label: res?.data?.data?.name, rest: res?.data?.data }));
                    navigate("/allocation/manage-allocation")
                }
            })
        }
    }, [createAllocationInfo, portfolioGroupIds, targetType]);

    //check this code for create allocation

    useEffect(() => {
        if (createAllocationInfo?.portfolioGroup !== null && createAllocationInfo?.portfolioGroup?.value !== 'all' && targetType !== "Portfolio Group") {
            dispatch(fetchPortfolioGroup(createAllocationInfo?.portfolioGroup?.slug))
        }
    }, [createAllocationInfo?.portfolioGroup?.value]);

    // load Allocation code starts here

    useEffect(() => {
        if (loadAllocationInfo?.portfolioGroup !== null && loadAllocationInfo?.portfolioGroup?.value !== 'all' && targetType !== "Portfolio Group") {
            dispatch(fetchPortfolioGroup(loadAllocationInfo?.portfolioGroup?.slug))
        }
        // only that time call when portfolioGroup is all and targetType is Portfolio Group
        else if (loadAllocationInfo?.portfolioGroup?.value === 'all' && targetType !== "Portfolio") {
            const payload = {
                // add all portfolioGroupIds here
            }
            dispatch(fetchAllocations(payload)).then((response) => {
                if (response?.data?.success) {
                    setAllAllocationsData(response?.data?.data?.records)
                }
            })
        }
    }, [loadAllocationInfo?.portfolioGroup?.value]);

    useEffect(() => {
        if (createAllocationInfo?.portfolioGroup?.value === 'all' || loadAllocationInfo?.portfolioGroup?.value === 'all') {
            dispatch(fetchAllPortfolios()).then((response) => {
                if (response?.data?.success) {
                    setAllPortfolios(response?.data?.data?.records)
                }
            })
        }
    }, [])
    // createAllocationInfo?.portfolioGroup?.value, loadAllocationInfo?.portfolioGroup?.value

    useEffect(() => {
        setTargetType("Portfolio Group");
    }, [allocationTab]);

    useEffect(() => {
        setCreateAllocationInfo({
            name: "",
            description: "",
            startDate: null,
            endDate: null,
            portfolio: null,
            portfolioGroup: null
        });
        setLoadAllocationInfo({
            portfolio: null,
            portfolioGroup: null,
            loadAllocation: null
        })
        setCreateAllocationErrors({});
    }, [targetType]);

    useEffect(() => {
        if (targetType !== "Portfolio Group") {
            setCreateAllocationInfo((prev) => {
                return {
                    ...prev,
                    portfolioGroup: {
                        value: 'all',
                        label: 'All'
                    }
                }
            })

        }

        if (targetType !== "Portfolio Group") {
            setLoadAllocationInfo((prev) => {
                return {
                    ...prev,
                    portfolioGroup: {
                        value: 'all',
                        label: 'All'
                    }
                }
            })
        }

    }, [targetType])

    const onTabChange = (value) => {
        setAllocationTab(value);
    }

    return (
        <DashboardMaster>
            {/* Most Outer Create and Load Tabs In Allocation */}
            <OuterTabs
                allocationTab={allocationTab}
                onTabChange={onTabChange}
                setTargetType={setTargetType}
                selectedPortfolioGroup={selectedPortfolioGroup}
                setSelectedPortfolioGroup={setSelectedPortfolioGroup}
                selectedPortfolio={selectedPortfolio}
                targetType={targetType}
                setSelectedPortfolio={setSelectedPortfolio}
                createAllocation={createAllocation}
                createAllocationInfo={createAllocationInfo}
                setCreateAllocationInfo={setCreateAllocationInfo}
                createAllocationErrors={createAllocationErrors}
                allPortfolios={allPortfolios}
                loadAllocationInfo={loadAllocationInfo}
                setLoadAllocationInfo={setLoadAllocationInfo}
                allAllocationsData={allAllocationsData}
                setAllAllocationsData={setAllAllocationsData}
            />
        </DashboardMaster>
    )
}

export default NewAllocation