import { allocationsV2ActionType } from '../../constants';

const allocationsV2Reducer = (state = [], action) => {
    switch (action.type) {
        case allocationsV2ActionType.ADD_ALLOCATION_V2:
            return action.payload;
        case allocationsV2ActionType.LOCK_UNLOCK_ASSET_ALLOCATION:
            return action.payload;
        default:
            return state;
    }
};
export default allocationsV2Reducer