import React, { useState } from 'react'
import { Input } from '../../components/ui/input'
import { Label } from '../../components/ui/label'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../components/ui/Card'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import { useDispatch, useSelector } from 'react-redux'
import Logo from "../../assets/images/logo-dark.svg"
import { Tokens } from '../../constants'
import { register } from '../../store/actions/auth/register'

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector(state => state)
    const [error, setError] = useState({});
    const token = localStorage.getItem(Tokens.ADMIN_TOKEN)
    const [userInfo, setUserInfo] = useState({
        name: "",
        email: '',
        password: '',
        confirm_password: '',
        organizationName: ''
    })

    // useEffect(() => {
    //   if (token) {
    //     navigate("/")
    //   }
    // }, []);

    const handleChange = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (userInfo.email === '') {
            errors['email'] = 'Please enter your email';
            isValid = false;
        }
        if (userInfo.password === '') {
            errors['password'] = 'Please enter a password';
            isValid = false;
        }
        if (userInfo.name === '') {
            errors['name'] = 'Please enter your email';
            isValid = false;
        }
        if (userInfo.confirm_password === '') {
            errors['confirm_password'] = 'Please enter a Confirm Password';
            isValid = false;
        }
        if (userInfo.confirm_password !== userInfo.password) {
            errors['confirm_password'] = 'Please enter a Valid Confirm Password';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        if (handleValidation()) {
            dispatch(register(userInfo, navigate))
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };
    return (
        <div className='flex justify-center items-center  h-screen p-4'>
            <Card className="max-w-2xl w-full relative">
                <div className='flex justify-center items-center pt-4'>
                    <img className='absolute -top-12 left-[30%] w-56' src={Logo} alt="logo image" />
                </div>
                <CardHeader>
                    <CardTitle className="text-2xl font-500">Sign up</CardTitle>
                    <CardDescription>
                        Provider your Information We Keep It Safe.
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2">
                    <div className="space-y-1">
                        <Label htmlFor="name">Name</Label>
                        <Input id="name" name="name" placeholder="johnDoe.." onChange={(e) => handleChange(e)} onKeyPress={handleKeyPress} autoComplete='off' />
                        <div className={error['name'] ? "text-rose-600" : ''}>{error['name'] ? error['name'] : null}</div>
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="organizationName">Organization Name</Label>
                        <Input id="organizationName" name="organizationName" placeholder="Organization.." onChange={(e) => handleChange(e)} onKeyPress={handleKeyPress} autoComplete='off' />
                        <div className={error['organizationName'] ? "text-rose-600" : ''}>{error['organizationName'] ? error['organizationName'] : null}</div>
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="email">Email</Label>
                        <Input id="email" name="email" placeholder="johnDoe@gmail.com" onChange={(e) => handleChange(e)} onKeyPress={handleKeyPress} autoComplete='off' />
                        <div className={error['email'] ? "text-rose-600" : ''}>{error['email'] ? error['email'] : null}</div>
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="password">Password</Label>
                        <Input id="password" name="password" type="password" placeholder="********" onChange={(e) => handleChange(e)} onKeyPress={handleKeyPress} autoComplete='off' />
                        <div className={error['password'] ? "text-rose-600" : ''}>{error['password'] ? error['password'] : null}</div>
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="confirm_password">Confirm Password</Label>
                        <Input id="confirm_password" type="password" name="confirm_password" placeholder="********" onChange={(e) => handleChange(e)} onKeyPress={handleKeyPress} autoComplete='off' />
                        <div className={error['confirm_password'] ? "text-rose-600" : ''}>{error['confirm_password'] ? error['confirm_password'] : null}</div>
                    </div>
                </CardContent>
                <CardFooter className="sm:flex items-center justify-between block space-y-2">
                    <Button className="sm:w-auto order-last w-full " onClick={handleSubmit}>Create Account</Button>
                    <Link to="/signin" className='text-[13px]  order-1 sm:order-3 '>Already have an account? <span className='text-sm font-semibold hover:underline text-gray-800' >Sign In</span> </Link>
                </CardFooter>
            </Card>
        </div>
    )
}

export default Register