
import { apiBaseURL } from '../../../../constants';
import apiConfig from '../../../../config/apiConfig';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
const ResearchNoteSelect = (props) => {
    const { onSelectResearchNote, isDisabled, formData } = props;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [loading, setLoading] = useState(false);
    const [hseMore, setHseMore] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchVal, setSearchVal] = useState('');

    useEffect(() => {
        // setTimeout(() => {
            fetchData(searchVal);
        //   }, 300);
    }, []);

    useEffect(() => {
        if (searchVal !== '') {
            setPage(1);
            setHseMore(true);
            setData([]);
            fetchData(searchVal);
        }
    }, [searchVal]);

    const fetchData = async () => {
        try {
            if (hseMore && !isDisabled) {
                setLoading(true);
                // const response = await apiConfig.get(apiBaseURL.ISSUERS + '?page=' + page + '&perPage=' + perPage + '&search=' + searchVal);
                const response = await apiConfig.get(apiBaseURL.ISSUERS + '?search=' + searchVal);
                // 
                const options = response.data?.data?.records?.length >= 1
                    ? response.data?.data?.records?.map((item) => {
                        return { value: item._id, label: item.name, slug: item.description };
                    })
                    : []
                // setData(prevData => [...prevData, ...options]);
                setData(options);
                // setHseMore(false)
                // setHseMore(response.data?.data?.totalPage === 0 ? false : page === response.data?.data?.totalPage ? false : response.data?.data?.records?.length === 0 ? false : true)
                setPage(prevPage => prevPage + 1);
                // setPage(pageNum + 1); // Always increment page number when fetching more data
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching assets:", error);
        }

    };

    const handleSelectItem = (item) => {
        setSelectedItem(item);
        onSelectResearchNote(item)
        // You can perform any other action when an item is selected
    };
    // handleScroll
    // h-[113px] overflow-auto
    return (
        <div className="custom-dropdown " onScroll={null}>
            <Select
                options={data}
                className="react_selector"
                onChange={handleSelectItem}
                onInputChange={(value) => {
                    setSearchVal(value)
                }}
                isDisabled={isDisabled}
                value={formData?.issuer}
                isLoading={loading}
                // onMenuScrollToBottom={() => {setHseMore(true); fetchData(searchVal)}}
                // onScroll={() => {setHseMore(true); fetchData(searchVal)}}
                // onMenuClose={() => {
                //     setPage(1);
                //     setHseMore(true);
                //     setData([]);
                // }}
                onMenuOpen={() => {setHseMore(true); fetchData(searchVal)}}
                placeholder={formData?.issuer && formData?._id ? formData?.issuer?.label : "Select an Issuer"}
                styles={{
                    groupHeading: (base) => ({
                        color: "black",
                    }),
                    menuList: (base) => ({
                        fontSize: "0.75rem",
                        overflow: "auto",
                        maxHeight: "180px",
                        lineHeight: '1rem',
                        fontWeight: 500,
                        borderRadius: '5px',
                    }),
                    container: (base, { isSelected, isFocused }) => ({
                        ...base,
                        ":focus": {
                            fontSize: "0.75rem",
                        }
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                        ...base,
                        cursor: "pointer",
                        borderRadius: '5px',
                        margin: "0px 5px",
                        backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
                        color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
                        ":hover": {
                            backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
                        },
                        ":focus": {
                            fontSize: "0.75rem",
                        }
                    }),
                }}
            />
        </div>
    );
};

export default ResearchNoteSelect;

