export const apiBaseURL = {
    CATEGORIES: "/class",
    STRATEGY: "/strategy",
    SUB_CATEGORIES: "/sub-class",
    COUNTRIES: "/countries",
    ASSETS_DETAILS: "/assets",
    ISSUERS: "/issuers",
    BYOD: "/custom-field",
    FINANCE_SEARCH: "/finance/search",
    FINANCE: "/finance",
    SENTRY: '/sentry',
    ADMIN_REGISTER: "/auth/register",
    ADMIN_LOGIN: "/auth/login",
    // INDICATOR_SOURCE: "/world-bank/indicator-source",
    INDICATOR_SOURCE: "/source",
    UNITED_NATIONS: "/united-nations",
    // INDICATOR_SOURCE_CATEGORIES: "/world-bank/indicator-categories",
    INDICATOR_SOURCE_CATEGORIES: "/world-bank/indicator-categories",
    USER_ANALYTICS: "/analytics",
    ANALYTICS_DATA: "/analytics-value",
    NEWS: '/news',
    REPORTS: '/reports',
    PORTFOLIOS: '/portfolios',
    PORTFOLIOS_COLUMN: 'portfolios-column',
    PORTFOLIOS_GROUP: '/portfolio-group',
    ALLOCATION: '/allocation',
    VIEWS: '/views',
    CUSTOM_FIELD: '/custom-field',
    ALLOCATION_MONITOR: '/allocation-monitor',
    TYPES: '/types',
    SUB_TYPES: '/sub-types',
    LOGOUT: "/logout",
    PORTFOLIO_TRANSACTION: "/asset-transactions",
    CUSTOM_FIELD_DATA: "/custom-field-data",
    CUSTOM_FIELD_VALUE: "/custom-field-value",
    CUSTOM_FIELD: "/custom-field",
    ANOMALY_DATA_ANALYSIS: "/anomaly-detection",
    RESEARCH_NOTES: "/research-notes",
    RESEARCH_NOTES_CONTENT: "/research-notes-content",
    ASSET_ISSUER_CUSTOM_FIELD: "/asset-issuer-custom-fields",
    FILE_UPLOAD: '/files/upload',
    FILE: '/files',
    USER: '/user',
    // admin baseurl
    ADMIN_USER: '/admin/users',
    ADMIN_CLASS: "/admin/class",
    ADMIN_SUB_CLASS: "/admin/sub-class",
    ADMIN_TYPE: "admin/types",
    ADMIN_SUB_TYPE: "admin/sub-types",
    CURVE: "yield-curves",
    TENOR: "tenor",
};
export const authActionType = {
    LOGIN_ADMIN: 'LOGIN_ADMIN',
    LOGOUT: 'LOGOUT',
    REGISTER: 'REGISTER'
};

export const Tokens = {
    ADMIN_TOKEN: 'auth_token',
    ADMIN: 'admin',
    ADMIN_ROLE: 'role'
};

export const errorMessage = {
    TOKEN_NOT_PROVIDED: 'Token not provided',
    TOKEN_EXPIRED: 'Token has expired',
    TOKEN_INVALID_AUTH: 'Invalid authentication Token',
    TOKEN_INVALID: 'Could not decode token: Error while decoding to JSON: Syntax error',
    TOKEN_INVALID_SIGNATURE: 'Token Signature could not be verified.'
};

export const toastifyType = {
    SHOW_TOAST: 'SHOW_TOAST',
    SHOW_SUCCESS_MESSAGE: 'SHOW_SUCCESS_MESSAGE',
    SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE'
}

export const constants = {
    SET_TOTAL_RECORD: 'SET_TOTAL_RECORD',
    IS_LOADING: 'IS_LOADING',
    IS_UPDATE_STATE: 'IS_UPDATE_STATE',
    IS_META: 'IS_META'
};


export const classActionType = {
    FETCH_CLASSES: 'FETCH_CLASSES',
    FETCH_CLASS: 'FETCH_CLASS',
    ADD_CLASS: 'ADD_CLASS',
    UPDATE_CLASS: 'UPDATE_CLASS',
    DELETE_CLASS: 'DELETE_CLASS',
};


export const strategyActionType = {
    FETCH_STRATEGIES: 'FETCH_STRATEGIES',
    FETCH_STRATEGY: 'FETCH_STRATEGY',
    ADD_STRATEGY: 'ADD_STRATEGY',
    UPDATE_STRATEGY: 'UPDATE_STRATEGY',
    DELETE_STRATEGY: 'DELETE_STRATEGY',
};

export const sentryActionType = {
    FETCH_SENTRYS: 'FETCH_SENTRYS',
    FETCH_SENTRY: 'FETCH_SENTRY',
    ADD_SENTRY: 'ADD_SENTRY',
    UPDATE_SENTRY: 'UPDATE_SENTRY',
    DELETE_SENTRY: 'DELETE_SENTRY',
};

export const portfoliosActionType = {
    FETCH_PORTFOLIOS: 'FETCH_PORTFOLIOS',
    FETCH_PORTFOLIOS_ALL: 'FETCH_PORTFOLIOS_ALL',
    FETCH_PORTFOLIO: 'FETCH_PORTFOLIO',
    ADD_PORTFOLIO: 'ADD_PORTFOLIO',
    UPDATE_PORTFOLIO: 'UPDATE_PORTFOLIO',
    DELETE_PORTFOLIO: 'DELETE_PORTFOLIO',
};

export const portfoliosGroupActionType = {
    FETCH_PORTFOLIOS_GROUPS: 'FETCH_PORTFOLIOS_GROUPS',
    FETCH_PORTFOLIO_GROUP: 'FETCH_PORTFOLIO_GROUP',
    ADD_PORTFOLIO_GROUP: 'ADD_PORTFOLIO_GROUP',
    UPDATE_PORTFOLIO_GROUP: 'UPDATE_PORTFOLIO_GROUP',
    DELETE_PORTFOLIO_GROUP: 'DELETE_PORTFOLIO_GROUP',
    FETCH_PORTFOLIOS_GROUP_Asset: 'FETCH_PORTFOLIOS_GROUP_Asset',
};

export const subClassActionType = {
    FETCH_SUB_CLASSES: 'FETCH_SUB_CLASSES',
    FETCH_SUB_CLASS: 'FETCH_SUB_CLASS',
    ADD_SUB_CLASS: 'ADD_SUB_CLASS',
    UPDATE_SUB_CLASS: 'UPDATE_SUB_CLASS',
    DELETE_SUB_CLASS: 'DELETE_SUB_CLASS',
};


export const countriesActionType = {
    FETCH_COUNTRIES: 'FETCH_COUNTRIES',
    FETCH_COUNTRIE: 'FETCH_COUNTRIE',
    ADD_COUNTRIES: 'ADD_COUNTRIES',
    UPDATE_COUNTRIES: 'UPDATE_COUNTRIES',
    DELETE_COUNTRIES: 'DELETE_COUNTRIES',
};

export const assetsDetailsActionType = {
    FETCH_ASSETS_DETAILS: 'FETCH_ASSETS_DETAILS',
    FETCH_ASSETS_DETAILS_ADDED: 'FETCH_ASSETS_DETAILS_ADDED',
    FETCH_ASSETS_DETAIL: 'FETCH_ASSETS_DETAIL',
    ADD_ASSETS_DETAIL: 'ADD_ASSETS_DETAIL',
    UPDATE_ASSETS_DETAIL: 'UPDATE_ASSETS_DETAIL',
    DELETE_ASSETS_DETAIL: 'DELETE_ASSETS_DETAIL',
};


export const assetsDetailsV2ActionType = {
    FETCH_ASSETS_DETAILS_V2: 'FETCH_ASSETS_DETAILS_V2',
    FETCH_ASSETS_DETAIL_V2: 'FETCH_ASSETS_DETAIL_V2',
    ADD_ASSETS_DETAIL_V2: 'ADD_ASSETS_DETAIL_V2',
    UPDATE_ASSETS_DETAIL_V2: 'UPDATE_ASSETS_DETAIL_V2',
    DELETE_ASSETS_DETAIL_V2: 'DELETE_ASSETS_DETAIL_V2',
    FETCH_ASSETS_HISTORY_V2: 'FETCH_ASSETS_HISTORY_V2',
    FETCH_ASSETS_BY_ISSUER_V2: 'FETCH_ASSETS_BY_ISSUER_V2',

};

export const issuerActionType = {
    FETCH_ISSUER: 'FETCH_ISSUER',
    FETCH_ISSUER: 'FETCH_ISSUER',
    ADD_ISSUER: 'ADD_ISSUER',
    UPDATE_ISSUER: 'UPDATE_ISSUER',
    DELETE_ISSUER: 'DELETE_ISSUER',
    FETCH_ISSUER_HISTORY: 'FETCH_ISSUER_HISTORY',
};

export const byodActionType = {
    FETCH_BYOD_V2: 'FETCH_BYOD_V2',
    FETCH_BYOD_VALUE_V2: 'FETCH_BYOD_VALUE_V2',
    FETCH_BYOD_VALUE2_V2: 'FETCH_BYOD_VALUE2_V2',
    ADD_BYOD_V2: 'ADD_BYOD_V2',
};

export const assetsFinanceSearchActionType = {
    FETCH_FINANCE_SEARCH_V2: 'FETCH_FINANCE_SEARCH_V2',
};

export const financePriceActionType = {
    FETCH_FINANCE_PRICE_V2: 'FETCH_FINANCE_PRICE_V2',
    GET_FINANCE_PRICE_V2: 'GET_FINANCE_PRICE_V2',
};

export const financeExchangesActionType = {
    FETCH_FINANCE_EXCHANGES_V2: 'FETCH_FINANCE_EXCHANGES_V2',
};




export const allocationsActionType = {
    FETCH_ALLOCATIONS: 'FETCH_ALLOCATIONS',
    FETCH_ALLOCATION: 'FETCH_ALLOCATION',
    ADD_ALLOCATION: 'ADD_ALLOCATION',
    UPDATE_ALLOCATION: 'UPDATE_ALLOCATION',
    DELETE_ALLOCATION: 'DELETE_ALLOCATION',
    HISTORY_ALLOCATION: 'HISTORY_ALLOCATION',
    FETCH_ALLOCATIONS_DETAILS: 'FETCH_ALLOCATIONS_DETAILS',
};

export const allocationsV2ActionType = {
    ADD_ALLOCATION_V2: 'ADD_ALLOCATION_V2',
    GET_ALLOCATED_PORTFOLIOS_V2: 'GET_ALLOCATED_PORTFOLIOS_V2',
    GET_ALLOCATED_AUTHORIZED_ASSETS_V2: 'GET_ALLOCATED_AUTHORIZED_ASSETS_V2',
    DELETE_ASSET_ALLOCATIONS: 'DELETE_ASSET_ALLOCATIONS',
    LOCK_UNLOCK_ASSET_ALLOCATION:'LOCK_UNLOCK_ASSET_ALLOCATION'
}

export const allocationsMonitorActionType = {
    FETCH_ALLOCATIONS_MONITOR: 'FETCH_ALLOCATIONS_MONITOR',
    FETCH_ALLOCATION_MONITOR: 'FETCH_ALLOCATION_MONITOR',
    ADD_ALLOCATION_MONITOR: 'ADD_ALLOCATION_MONITOR',
    UPDATE_ALLOCATION_MONITOR: 'UPDATE_ALLOCATION_MONITOR',
    DELETE_ALLOCATION_MONITOR: 'DELETE_ALLOCATION_MONITOR',
};
export const curveActionType = {
    FETCH_CURVES: 'FETCH_CURVES',
};
export const tenorActionType = {
    FETCH_TENOR: 'FETCH_TENOR',
};

export const viewsActionType = {
    FETCH_VIEWS: 'FETCH_VIEWS',
    CUSTOM_FIELD: 'CUSTOM_FIELD',
    FETCH_VIEW: 'FETCH_VIEW',
    ADD_VIEW: 'ADD_VIEW',
    UPDATE_VIEW: 'UPDATE_VIEW',
    DELETE_VIEW: 'DELETE_VIEW',
};

export const customFieldsDataType = {
    FETCH_CUSTOM_DATA: 'FETCH_CUSTOM_DATA'
}

export const portfolioActionType = {
    FETCH_portfolio_V2: 'FETCH_portfolio_V2',
    ADD_portfolio_V2: 'ADD_portfolio_V2',
    UPDATE_portfolio_V2: 'UPDATE_portfolio_V2',
    DELETE_portfolio_V2: 'DELETE_portfolio_V2',
};

export const assetsAuthorizeActionType = {
    FETCH_ASSETS_AUTHORIZE_V2: 'FETCH_ASSETS_AUTHORIZE_V2',
    ADD_ASSETS_AUTHORIZE_V2: 'ADD_ASSETS_AUTHORIZE_V2',
    UPDATE_ASSETS_AUTHORIZE_V2: 'UPDATE_ASSETS_AUTHORIZE_V2',
    DELETE_ASSETS_AUTHORIZE_V2: 'DELETE_ASSETS_AUTHORIZE_V2',
};

export const chartDataActionType = {
    FETCH_CHART_DATA: 'FETCH_CHART_DATA',
};

export const indicatorSourceActionType = {
    FETCH_INDICATOR_SOURCES: 'FETCH_INDICATOR_SOURCES',
    FETCH_INDICATOR_SOURCE: 'FETCH_INDICATOR_SOURCE',
    ADD_INDICATOR_SOURCE: 'ADD_INDICATOR_SOURCE',
    UPDATE_INDICATOR_SOURCE: 'UPDATE_INDICATOR_SOURCE',
    DELETE_INDICATOR_SOURCE: 'DELETE_INDICATOR_SOURCE',
};

export const indicatorSourceCategoryActionType = {
    FETCH_INDICATOR_SOURCES_CATEGORIES: 'FETCH_INDICATOR_SOURCES_CATEGORIES',
    FETCH_INDICATOR_SOURCE_CATEGORIE: 'FETCH_INDICATOR_SOURCE_CATEGORIE',
    ADD_INDICATOR_SOURCE_CATEGORIE: 'ADD_INDICATOR_SOURCE_CATEGORIE',
    UPDATE_INDICATOR_SOURCE_CATEGORIE: 'UPDATE_INDICATOR_SOURCE_CATEGORIE',
    DELETE_INDICATOR_SOURCE_CATEGORIE: 'DELETE_INDICATOR_SOURCE_CATEGORIE',

    FETCH_INDICATOR_GOALS: 'FETCH_INDICATOR_GOALS',
    FETCH_INDICATOR_TARGET: 'FETCH_INDICATOR_TARGET',
    FETCH_TARGET_INDICATORS: 'FETCH_TARGET_INDICATORS',
};

export const indicatorActionType = {
    FETCH_INDICATORS: 'FETCH_INDICATORS',
    FETCH_INDICATOR: 'FETCH_INDICATOR',
    ADD_INDICATOR: 'ADD_INDICATOR',
    UPDATE_INDICATOR: 'UPDATE_INDICATOR',
    DELETE_INDICATOR: 'DELETE_INDICATOR',
};

export const seriesActionType = {
    FETCH_SERIES: 'FETCH_SERIES',
    FETCH_ONE_SERIES: 'FETCH_ONE_SERIES',
    ADD_SERIES: 'ADD_SERIES',
    UPDATE_SERIES: 'UPDATE_SERIES',
    DELETE_SERIES: 'DELETE_SERIES',
};

export const userAnalyticsActionType = {
    FETCH_USER_ANALYTICS: 'FETCH_USER_ANALYTICS',
    FETCH_USER_ANALYTIC: 'FETCH_USER_ANALYTIC',
    ADD_USER_ANALYTIC: 'ADD_USER_ANALYTIC',
    UPDATE_USER_ANALYTIC: 'UPDATE_USER_ANALYTIC',
    DELETE_USER_ANALYTIC: 'DELETE_USER_ANALYTIC',
};

export const viewDocFileActionType = {
    VIEW_DOC_FILE: 'VIEW_DOC_FILE',
}

export const analyticsDataActionType = {
    FETCH_ANALYTICS_DATAS: 'FETCH_ANALYTICS_DATAS',
}

export const newsActionType = {
    FETCH_NEWS: 'FETCH_NEWS',
};

export const portfolioColumnActionType = {
    FETCH_PORTFOLIOS_COLUMN: 'FETCH_PORTFOLIOS_COLUMN',
    FETCH_PORTFOLIOS_GROUP_COLUMN: 'FETCH_PORTFOLIOS_GROUP_COLUMN',
    FETCH_ALLOCATION_COLUMN: 'FETCH_ALLOCATION_COLUMN',
    FETCH_ALLOCATION_GROUP_COLUMN: 'FETCH_ALLOCATION_GROUP_COLUMN',
};

export const analyticReportsActionType = {
    FETCH_ANALYTIC_REPORTS: 'FETCH_ANALYTIC_REPORTS',
    FETCH_ANALYTIC_REPORTS_TOW: 'FETCH_ANALYTIC_REPORTS_TOW',
};


export const reportsActionType = {
    FETCH_REPORTS: 'FETCH_REPORTS',
    FETCH_REPORTS_TOW: 'FETCH_REPORTS_TOW',
};
export const researchActionType = {
    ADD_RESEARCH_NOTES: 'ADD_RESEARCH_NOTES',
    FETCH_RESEARCH_NOTES: 'FETCH_RESEARCH_NOTES',
    EDIT_RESEARCH_NOTES: 'EDIT_RESEARCH_NOTES',
    DELETE_RESEARCH_NOTES: 'DELETE_RESEARCH_NOTES',
};
export const researchNoteContentTagType = {
    FETCH_RESEARCH_NOTE_TAGS: 'FETCH_RESEARCH_NOTE_TAGS',
    ADD_RESEARCH_NOTE_TAGS: 'ADD_RESEARCH_NOTE_TAGS',
    DELETE_RESEARCH_NOTE_TAGS: 'DELETE_RESEARCH_NOTE_TAGS',
};
export const researchNoteContentActionType = {
    FETCH_ONE_RESEARCH_NOTE: 'FETCH_ONE_RESEARCH_NOTE',
};

export const typeActionType = {
    FETCH_TYPES: 'FETCH_TYPES',
    ADD_TYPES: 'ADD_TYPES',
    DELETE_TYPES: 'DELETE_TYPES',
    UPDATE_TYPES: 'UPDATE_TYPES',
};
export const subTypeActionType = {
    FETCH_SUBTYPES: 'FETCH_SUBTYPES',
    FETCH_SUBTYPE: 'FETCH_SUBTYPE',
    ADD_SUBTYPES: 'ADD_SUBTYPES',
    DELETE_SUBTYPES: 'DELETE_SUBTYPES',
    UPDATE_SUBTYPES: 'UPDATE_SUBTYPES',
};

export const AdminUserActionType = {
    FETCH_USERS: 'FETCH_USERS',
    FETCH_USER: 'FETCH_USER',
    ADD_USER: 'ADD_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER',
    VERIFY_USER: 'VERIFY_USER',
};

export const adminClassActionType = {
    FETCH_CLASSES: 'FETCH_CLASSES',
    FETCH_CLASS: 'FETCH_CLASS',
    ADD_CLASS: 'ADD_CLASS',
    UPDATE_CLASS: 'UPDATE_CLASS',
    DELETE_CLASS: 'DELETE_CLASS',
};
export const adminSubClassActionType = {
    FETCH_SUB_CLASSES: 'FETCH_SUB_CLASSES',
    FETCH_SUB_CLASS: 'FETCH_SUB_CLASS',
    ADD_SUB_CLASS: 'ADD_SUB_CLASS',
    UPDATE_SUB_CLASS: 'UPDATE_SUB_CLASS',
    DELETE_SUB_CLASS: 'DELETE_SUB_CLASS',
};
export const adminTypeActionType = {
    FETCH_TYPES: 'FETCH_TYPES',
    ADD_TYPES: 'ADD_TYPES',
    DELETE_TYPES: 'DELETE_TYPES',
    UPDATE_TYPES: 'UPDATE_TYPES',
};
export const adminSubTypeActionType = {
    FETCH_SUBTYPES: 'FETCH_SUBTYPES',
    FETCH_SUBTYPE: 'FETCH_SUBTYPE',
    ADD_SUBTYPES: 'ADD_SUBTYPES',
    DELETE_SUBTYPES: 'DELETE_SUBTYPES',
    UPDATE_SUBTYPES: 'UPDATE_SUBTYPES',
};
export const PortfolioTransactionActionType = {
    FETCH_INVESTED_ASSETS: 'FETCH_INVESTED_ASSETS',
    FETCH_INVESTED_ASSET: 'FETCH_INVESTED_ASSET',
    UPDATE_INVESTED_ASSETS: 'UPDATE_INVESTED_ASSETS',
    DELETE_INVESTED_ASSETS: 'DELETE_INVESTED_ASSETS',
    ADD_INVESTED_ASSETS: 'ADD_INVESTED_ASSETS',
};

export const saleTransactionActionType = {
    FETCH_INVESTED_ASSETS_SALE: 'FETCH_INVESTED_ASSETS_SALE',
};

export const profileActionType = {
    GET_PROFILE_INFO: 'GET_PROFILE_INFO',
    CHANGE_PROFILE_PASSWORD: 'CHANGE_PROFILE_PASSWORD'
}





export const Filters = {
    PAGE: 1,
    OBJ: {
        totalRecords: 0,
        totalPages: 0,
        page: 1,
        perPage: 10,
        direction: 'asc',
        search: '',
        adminName: 'admin',
        categoryId: '',
        created_at: 'created_at',
        status: '',
        payment_status: '',
        payment_type: '',
        product_unit: '',
        base_unit: ''
    }
};