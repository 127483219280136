
import { apiBaseURL, classActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const fetchClass = (include, filter, isLoading) => async (dispatch) => {
  isLoading !== false && dispatch(setLoading(true))
  try {
    const filterData = `?type=${include ? include : ''}&page=${filter?.page ? filter?.page : ''}&perPage=${filter?.perPage ? filter?.perPage : ''}`
    const response = await apiConfig.get(apiBaseURL.CATEGORIES + filterData)
    dispatch({ type: classActionType.FETCH_CLASSES, payload: response.data?.data });
    filter && dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage || 1, pageSize: 10, totalItems: response?.data?.data?.totalRecords }))
    dispatch(setLoading(false))
    return response;
  } catch (error) {
    dispatch(setLoading(false))
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
  }
}


export const addClass = (data, onHide) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.CATEGORIES, data);
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchClass());
    onHide()
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deleteClass = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.delete(apiBaseURL.CATEGORIES + "/" + id);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchClass());
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updateClass = (id, data, onHide) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.CATEGORIES + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchClass());
    onHide()
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};