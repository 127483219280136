import apiConfig from '../../config/apiConfig';
import { allocationsActionType, apiBaseURL, toastifyType } from '../../constants';
import { addTost } from './toastAction';

export const fetchAllocationHistory = (id) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ALLOCATION + '/history/' + id)
        dispatch({ type: allocationsActionType.HISTORY_ALLOCATION, payload: response.data?.data });
        return response;
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
        return error;
    }
}