import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Highlighter from 'react-highlight-words';

const CustomCreatableSelect = (props) => {
  const {
    options = [],
    classNamePrefix = '',
    className,
    onChange,
    value = [],
    placeholder,
    styles,
    isMulti = false,
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isSearchable = false,
    components = null,
    closeMenuOnSelect = !isMulti,
    onCheckedCustomComponent = null,
    menuIsOpen = false,
    readOnly = false,
    defaultValue = null,
  } = props;

  // Add a "Select All" option if in multi-select mode
  const extendedOptions = options;

  // Custom option renderer to add checkboxes and highlight search text

  const customProps = {
    options: extendedOptions,
    classNamePrefix,
    className: `w-full ${className}`,
    isMulti,
    isClearable,
    value,
    isDisabled,
    isLoading,
    onChange,
    defaultValue,
    isReadOnly: readOnly,
    placeholder: placeholder || 'Select an option',
    filterOption: (option, inputValue) => {
      if (!inputValue) return true;
      const searchTerms = inputValue.toLowerCase().trim().split(' ');
      return searchTerms.every((term) => option.label.toLowerCase().includes(term));
    },
    formatOptionLabel: (data, { inputValue }) => (
      <Highlighter
        highlightClassName="highlighted-text bg-blue-200"
        searchWords={[inputValue]}
        textToHighlight={data.label}
      />
    ),
    formatCreateLabel: () => null,
    isValidNewOption: () => false,
    styles,
    closeMenuOnSelect,
    onCheckedCustomComponent,
    ...(components !== null ? { components } : {}), // Conditionally add the components prop if it exists
  };


  return (
    <CreatableSelect
      {...customProps}      
    />
  );
};

export default CustomCreatableSelect;
