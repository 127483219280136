import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { modelSentry, displayAnalysisType } from "../../../../static/static";
// import { fetchAssetsDetails } from '../../../../store/actions/AssetAction';
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FromDatePicker } from "../../../../components/ui/DatePickerNew";
import { apiBaseURL } from "../../../../constants";
import apiConfig from '../../../../config/apiConfig';
import moment from "moment-js";
import { Switch } from "../../../../components/ui/switch";
import { addSentry } from "../../../../store/actions/sentryAction";
import { Checkbox } from "../../../../components/ui/checkbox";
import TestSelect from "../selector/AssetSelect";
import AssetSelect from "../selector/AssetSelect";
import IssuerSelect from "../selector/issuerSelect";
import SelectDataSelect from "../selector/SelectDataSelect";
const CreateSentry = (props) => {
  const { show, onHide, setIsChecked, setIsCheckDisabled } = props;
  const { } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [startFormDate, setStartFormDate] = useState(null);
  const [startToDate, setStartToDate] = useState(null);
  const [isCheck, setIsCheck] = useState(false);
  const [assetIssuerId, setAssetIssuerId] = useState(null);

  const [formData, setFormData] = useState({
    assets: null,
    issuer: null,
    model: null,
    from: "",
    to: "",
    dataSet: null,
    display: displayAnalysisType[0] || null,
    table: true,
    scatterChart: false,
    timeSeriesChart: false,
    group: false
  });
  const [reloadOptionsData, setReloadOptionsData] = useState(false); // State to trigger reload of options
  const [selectedOption, setSelectedOption] = useState(null);
  // useEffect(() => {
  //   if (show) {
  //     dispatch(fetchFinancePrice(0, ""));
  //   }
  // }, [show]);

  useEffect(() => {
    if (!show) {
      setFormData({
        assets: null,
        issuer: null,
        model: null,
        from: "",
        to: "",
        dataSet: null,
        display: displayAnalysisType[0] || null,
        table: true,
        scatterChart: false,
        timeSeriesChart: false,
        group: false
      })
      setIsCheck(false)
    }
  }, [show]);

  // first time load data state 
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isFirstTimeIssuer, setIsFirstTimeIssuer] = useState(true);
  const [isFirstTimeData, setIsFirstTimeData] = useState(true);
  const [isFirstTimeData2, setIsFirstTimeData2] = useState(true);
  // asset op 
  const loadOptions = async (search, loadedOptions, { page, perPage }) => {
    try {
      if (isFirstTime && isCheck) {
        setIsFirstTime(false)
        return {
          options: [],
          hasMore: true,
          additional: {
            page: 1,
            perPage: 5
          },
        };
      } else {
        const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '/raw' + '?page=' + page + '&perPage=' + 5 + '&name=' + search);
        const options = response.data?.data?.records?.length >= 1
          ? response.data?.data?.records?.map((item) => {
            return { value: item._id, label: item.assetName, slug: item.description };
          })
          : []
        return {
          options: options,
          hasMore: page === response.data?.data?.totalPage ? false : response.data?.data?.records?.length === 0 ? false : true,
          additional: {
            page: page + 1,
            perPage: 5 + perPage
          },
        };
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const onSelectAsset = (value) => {
    setFormData({ ...formData, assets: value, dataSet: null })
    setAssetIssuerId(value)
  }

  const onSelectIssuer = (value) => {
    setFormData({ ...formData, issuer: value, dataSet: null })
    setAssetIssuerId(value)
  }

  const onSelectIData = (value) => {
    setFormData({ ...formData, dataSet: value })
  }

  // issuer op 
  const loadOptionsIssuer = async (search, loadedOptions, { page, perPage }) => {
    try {
      if (isFirstTimeIssuer && !isCheck) {
        setIsFirstTimeIssuer(false)
        return {
          options: [],
          hasMore: true,
          additional: {
            page: 1,
            perPage: 5
          },
        };
      } else {
        const response = await apiConfig.get(apiBaseURL.ISSUERS + '?page=' + page + '&perPage=' + 5 + '&search=' + search);

        const options = response.data?.data?.records?.length >= 1
          ? response.data?.data?.records?.map((item) => {
            return { value: item._id, label: item.name, slug: item.issuerId };
          })
          : []
        return {
          options: options,
          hasMore: page === response.data?.data?.totalPage ? false : response.data?.data?.records?.length === 0 ? false : true,
          additional: {
            page: page + 1,
            perPage: 5 + perPage
          },
        };
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };
  // data set with asset op 
  const loadOptionsData = async (search, loadedOptions, { page, perPage }) => {
    try {
      // ;
      if (isFirstTimeData || formData.assets?.value === undefined) {
        setIsFirstTimeData(false);
        setReloadOptionsData(false); // Reset reloadOptionsData state after triggering reload
        return {
          options: [],
          hasMore: true,
          additional: {
            page: 1,
            perPage: 5,
          },
        };
      } else {
        // 
        const response = await apiConfig.get(
          apiBaseURL.SENTRY +
          "/data-set?page=" +
          page +
          "&perPage=" +
          5 +
          "&name=" +
          search +
          "&group=" +
          "Assets" +
          "&id=" +
          formData.assets?.value
        );
        const options = response.data?.data?.records?.length >= 1
          ? response.data?.data?.records?.map((item) => {
            return { value: item.value, label: item.title, slug: item.value };
          })
          : [];
        return {
          options: options,
          hasMore: page === response.data?.data?.totalPage
            ? false
            : response.data?.data?.records?.length === 0
              ? false
              : true,
          additional: {
            page: page + 1,
            perPage: 5 + perPage,
          },
        }
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };
  // data set with issuer op 
  const loadOptionsData2 = async (search, loadedOptions, { page, perPage }) => {
    try {
      // ;
      if (isFirstTimeData2 || formData.issuer?.value === undefined) {
        setIsFirstTimeData2(false);
        setReloadOptionsData(false); // Reset reloadOptionsData state after triggering reload
        return {
          options: [],
          hasMore: true,
          additional: {
            page: 1,
            perPage: 5,
          },
        };
      } else {
        const response = await apiConfig.get(
          apiBaseURL.SENTRY +
          "/data-set?page=" +
          page +
          "&perPage=" +
          5 +
          "&name=" +
          search +
          "&group=" +
          "Issuers" +
          "&id=" +
          formData.issuer?.value
        );
        const options =
          response.data?.data?.records?.length >= 1
            ? response.data?.data?.records?.map((item) => {
              return { value: item.value, label: item.title, slug: item.value };
            })
            : [];
        return {
          options: options,
          hasMore:
            page === response.data?.data?.totalPage
              ? false
              : response.data?.data?.records?.length === 0
                ? false
                : true,
          additional: {
            page: page + 1,
            perPage: 5 + perPage,
          },
        };
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };
  const onChangAssetOptions = (selectedOption) => {
    setFormData({ ...formData, assets: selectedOption });
    setError({});
  };
  const onChangIssuerOptions = (selectedOption) => {
    setFormData({ ...formData, issuer: selectedOption });
    setError({});
  };
  // const onChangAssetOptions = (value) => {
  //   setFormData({ ...formData, assets: value });
  //   setError({});
  // };
  const onChangModelSentryOptions = (value) => {
    setFormData({ ...formData, model: value });
    setError({});
  };
  const onChangDateSetPriceOptions = (value) => {
    setSelectedOption(value)
    setFormData({ ...formData, dataSet: value });
    setError({});
  };

  const modelSentryOptions =
    modelSentry && modelSentry?.length >= 1
      ? modelSentry?.map((item) => {
        return { value: item.id, label: item.label, symbol: item.symbol };
      })
      : [];

  const handleValidation = () => {
    let errors = {};
    let isValid = true;

    if (formData.assets === null && !isCheck) {
      errors["asset"] = "Please select an asset";
      isValid = false;
    }
    if (formData.issuer === null && isCheck) {
      errors["issuer"] = "Please select an issuer";
      isValid = false;
    }
    if (formData.model === null) {
      errors["modelType"] = "Please select a Model type";
      isValid = false;
    }

    if (formData.from === "") {
      errors["from"] = "Please select a valid from date";
      isValid = false;
    }

    if (formData.to === "") {
      errors["to"] = "Please select a valid to date";
      isValid = false;
    }

    if (formData.dataSet === null) {
      errors["dataSet"] = "Please select a Data Set";
      isValid = false;
    }

    if (!formData.table && !formData.scatterChart && !formData.timeSeriesChart) {
      errors["display"] = "Please select a Display";
      isValid = false;
    } else {
      errors["display"] = "";
    }

    setError(errors);
    return isValid;
  };


  const prepareData = () => {
    // ?&start_date=2023-1-1&end_date=2024-1-1&group=Assets&model=KNN&dataset_key=Price - Close&dataset_value=close&display=all
    const data = {
      id: assetIssuerId !== null ? assetIssuerId?.value : null,
      field_id: formData?.dataSet !== null ? formData?.dataSet?.fieldId === null ? '' : formData?.dataSet?.fieldId : '',
      start_date: startFormDate !== null ? moment(startFormDate).format("YYYY-MM-DD") : '',
      end_date: startToDate !== null ? moment(startToDate).format("YYYY-MM-DD") : '',
      group: formData?.dataSet !== null ? formData?.dataSet?.group : '',
      model: formData?.model !== null ? formData?.model?.symbol : '',
      dataset_key: formData?.dataSet !== null ? formData?.dataSet?.label : '',
      dataset_value: formData?.dataSet !== null ? formData?.dataSet?.value : '',
      display: 'all'
    }
    const updateData = {
      asset_id: formData.assets !== null ? formData?.assets?.value : null,
      issuer_id: formData.issuer !== null ? formData?.issuer?.value : null,
      start_date: startFormDate !== null ? moment(startFormDate).format("YYYY-MM-DD") : '',
      end_date: startToDate !== null ? moment(startToDate).format("YYYY-MM-DD") : '',
      asset_type: 'asset',
      model: formData?.model !== null ? formData?.model?.symbol : '',
      dataSet: formData?.dataSet !== null ? formData?.dataSet?.slug : '',
      display: 'all',
      // display: formData?.display !== null ? formData?.display?.label.toLocaleLowerCase() : '',
    }
    return data
  }
  const onClickSave = () => {
    if (handleValidation()) {
      const data = prepareData();
      setIsChecked(false)
      localStorage.setItem('runAnalysis', JSON.stringify({ ...data, table: formData.table, scatterChart: formData.scatterChart, timeSeriesChart: formData.timeSeriesChart }))
      dispatch(addSentry(data))
      setIsCheck(false)
      setIsCheckDisabled(false)
      onHide()
    }
  };


  const onChangeHandel = (value) => {
    setIsCheck(value)
    setSelectedOption(null); // Reset selected option when switching genders
    setFormData(prevState => ({
      ...prevState,
      assets: null,
      issuer: null,
      model: null,
      from: prevState.from,
      to: prevState.to,
      dataSet: null,
      display: displayAnalysisType[0] || null,
      table: true,
      scatterChart: false,
      timeSeriesChart: false,
      group: value
    }));
    setError({})
    // setIsFirstTimeData(true)
    // setReloadOptionsData(!reloadOptionsData); // Toggle reloadOptionsData state to trigger reload
  }

  return (
    <Dialog open={show} onOpenChange={onHide} className="p-2">
      <DialogContent className="xl:max-w-[60%] sm:max-w-[70%] gap-6 z-50">
        <DialogHeader className="mb-4">
          <DialogTitle className="text-center">{"Run Analysis"}</DialogTitle>
        </DialogHeader>
        <div className="dialog-body">
          <div className="grid grid-cols-1 lg:grid-cols-1 mb-5 gap-5">
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="flex items-center space-x-2">
                <Label htmlFor="Asset">Asset</Label>
                <Switch checked={formData.group} onCheckedChange={(value) => onChangeHandel(value)} />
                <Label htmlFor="BYOD">Issuer</Label>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper lg:col-span-2 col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Assets:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper lg:col-span-10  col-span-8">
                <AssetSelect onSelectAsset={onSelectAsset} isDisabled={isCheck} />
                {/* <AsyncPaginate
                  cacheOptions
                  defaultOptions
                  isDisabled={isCheck}
                  loadOptions={(search, loadedOptions, { page, perPage }) => loadOptions(search, loadedOptions, { page, perPage })}
                  onChange={onChangAssetOptions}
                  value={formData.assets}
                  placeholder={'Select Asset'}
                  className="react_selector"
                  additional={{
                    page: 1,
                    perPage: 5
                  }}
                  styles={{
                    groupHeading: (base) => ({
                      color: "black",
                    }),
                    menuList: (base) => ({
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px',
                    }),
                    container: (base, { isSelected, isFocused }) => ({
                      ...base,
                      fontSize: isFocused ? "0.75rem" : "inherit" // Adjust font size when focused
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                      ...base,
                      cursor: "pointer",
                      borderRadius: '5px',
                      margin: "0px 5px",
                      backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
                      color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
                      ":hover": {
                        backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
                      },
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                  }}
                /> */}
                {error["asset"] && (
                  <div
                    className={` ${error["asset"] ? "text-red text-[10px]" : ""
                      } px-2 py-1`}
                  >
                    {error["asset"] ? error["asset"] : null}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper lg:col-span-2 col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Issuer:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper lg:col-span-10  col-span-8">
                {/* onSelectIssuer */}
                <IssuerSelect onSelectIssuer={onSelectIssuer} isDisabled={!isCheck} />
                {/* <AsyncPaginate
                  key={reloadOptionsData} // Key prop to trigger re-render when reloadOptionsData changes
                  cacheOptions
                  isDisabled={!isCheck}
                  defaultOptions
                  className="react_selector"
                  onClick={() => setReloadOptionsData(!reloadOptionsData)} // Add onClick to trigger reload
                  loadOptions={(search, loadedOptions, { page, perPage }) => loadOptionsIssuer(search, loadedOptions, { page, perPage })}
                  onChange={onChangIssuerOptions}
                  value={formData.issuer}
                  placeholder={'Select Issuer'}
                  additional={{
                    page: 1,
                    perPage: 5
                  }}
                  styles={{
                    groupHeading: (base) => ({
                      color: "black",
                    }),
                    menuList: (base) => ({
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px',
                    }),
                    container: (base, { isSelected, isFocused }) => ({
                      ...base,
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                      ...base,
                      cursor: "pointer",
                      borderRadius: '5px',
                      margin: "0px 5px",
                      backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
                      color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
                      ":hover": {
                        backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
                      },
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                  }}
                /> */}
                {error["issuer"] && (
                  <div
                    className={` ${error["issuer"] ? "text-red text-[10px]" : ""
                      } px-2 py-1`}
                  >
                    {error["issuer"] ? error["issuer"] : null}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="username"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  From:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <FromDatePicker
                  onDateSelect={(date) => {
                    setFormData({ ...formData, from: date });
                    setStartFormDate(date);
                  }}
                  placeholderText="YYYY-MM-DD"
                  selectedFromDate={startFormDate}
                />
                <div
                  className={` ${error["from"] ? "text-red text-[10px]" : ""
                    } px-2 py-1`}
                >
                  {error["from"] ? error["from"] : null}
                </div>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="username"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  To:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <FromDatePicker
                  onDateSelect={(date) => {
                    setFormData({ ...formData, to: date });
                    setStartToDate(date);
                  }}
                  placeholderText="YYYY-MM-DD"
                  minDate={startFormDate}
                  selectedFromDate={startFormDate}
                />
                <div
                  className={` ${error["to"] ? "text-red text-[10px]" : ""
                    } px-2 py-1`}
                >
                  {error["to"] ? error["to"] : null}
                </div>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Select Model:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <Select
                  onValueChange={(e) =>
                    onChangModelSentryOptions(JSON.parse(e))
                  }
                  defaultValue={formData.model}
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Model"
                    >
                      {formData.model !== null
                        ? formData.model.label
                        : "Select Model"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {modelSentryOptions && modelSentryOptions.length > 0 ? (
                        modelSentryOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <div
                  className={` ${error["modelType"] ? "text-red text-[10px]" : ""
                    } px-2 py-1`}
                >
                  {error["modelType"] ? error["modelType"] : null}
                </div>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Select Data:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <SelectDataSelect onSelectIData={onSelectIData} isDisabled={formData.assets === null && formData.issuer === null} id={formData.group === false ? formData.assets?.value : formData.issuer?.value}
                  group={formData.group === false ? 'Assets' : 'Issuers'} value={formData.dataSet}
                />
                {/* {formData.group === false ? <AsyncPaginate
                  cacheOptions
                  defaultOptions
                  isDisabled={formData.assets === null && formData.issuer === null}
                  className="react_selector"
                  loadOptions={(search, loadedOptions, { page, perPage }) => loadOptionsData(search, loadedOptions, { page, perPage })}
                  onChange={onChangDateSetPriceOptions}
                  value={formData.dataSet}
                  placeholder={'Select Data'}
                  additional={{
                    page: 1,
                    perPage: 5
                  }}
                  styles={{
                    groupHeading: (base) => ({
                      color: "black",
                    }),
                    menuList: (base) => ({
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px',
                    }),
                    container: (base, { isSelected, isFocused }) => ({
                      ...base,
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                      ...base,
                      cursor: "pointer",
                      borderRadius: '5px',
                      margin: "0px 5px",
                      backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
                      color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
                      ":hover": {
                        backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
                      },
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                  }}
                /> : ''}
                {formData.group === true ? <AsyncPaginate
                  cacheOptions
                  defaultOptions
                  isDisabled={formData.assets === null && formData.issuer === null}
                  className="react_selector"
                  loadOptions={(search, loadedOptions, { page, perPage }) => loadOptionsData2(search, loadedOptions, { page, perPage })}
                  onChange={onChangDateSetPriceOptions}
                  value={formData.dataSet}
                  hideSelectedOptions={formData.group === true}
                  placeholder={'Select Data'}
                  additional={{
                    page: 1,
                    perPage: 5
                  }}
                  styles={{
                    groupHeading: (base) => ({
                      color: "black",
                    }),
                    menuList: (base) => ({
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px',
                    }),
                    container: (base, { isSelected, isFocused }) => ({
                      ...base,
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                      ...base,
                      cursor: "pointer",
                      borderRadius: '5px',
                      margin: "0px 5px",
                      backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
                      color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
                      ":hover": {
                        backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
                      },
                      ":focus": {
                        fontSize: "0.75rem",
                      }
                    }),
                  }}
                /> : ''} */}
                <div
                  className={` ${error["dataSet"] ? "text-red text-[10px]" : ""
                    } px-2 py-1 `}
                >
                  {error["dataSet"] ? error["dataSet"] : null}
                </div>
              </div>
            </div>

            <div className="form-group grid grid-cols-12 items-center gap-1 ">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Display:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="flex md:flex-nowrap flex-wrap">
                <div className="flex items-center space-x-2 me-3 lg:mb-[0] mb-[3px] ">
                  <Checkbox id="Table" className="border-black rounded-[4px]" checked={formData.table} onCheckedChange={(value) => {setFormData({ ...formData, table: value }); setError({
                      ...error,
                      display: ""
                    })}} />
                  <Label
                    // htmlFor="Table"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-nowrap"
                    style={{
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px'
                    }}

                  >
                    Table
                  </Label>
                </div>
                <div className="flex items-center space-x-2  me-3 lg:mb-[0] mb-[3px] ">
                  <Checkbox id="Scatter" checked={formData?.scatterChart} className="  border-black rounded-[4px]" onCheckedChange={(value) => {
                    setFormData({ ...formData, scatterChart: value }); setError({
                      ...error,
                      display: ""
                    })
                  }} />
                  <Label
                    // htmlFor="Scatter"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-nowrap"
                    style={{
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px'
                    }}
                  >
                    Scatter Chart
                  </Label>
                </div>
                <div className="flex items-center space-x-2  me-3 lg:mb-[0] mb-[3px] ">
                  <Checkbox id="Series" checked={formData?.timeSeriesChart} className=" border-black rounded-[4px]" onCheckedChange={(value) => {
                    setFormData({ ...formData, timeSeriesChart: value }); setError({
                      ...error,
                      display: ""
                    })
                  }} />
                  <Label
                    // htmlFor="Series"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-nowrap"
                    style={{
                      fontSize: "0.75rem",
                      overflow: "auto",
                      maxHeight: "180px",
                      lineHeight: '1rem',
                      fontWeight: 500,
                      borderRadius: '5px'
                    }}
                  >
                    Time Series Chart
                  </Label>
                </div>
              </div>
            </div>
          </div>
          {error["display"] && <div
            className={` ${error["display"] ? "text-red text-[10px]" : ""
              } px-2 py-1 ps-[120px] `}
          >
            {error["display"] ? error["display"] : null}
          </div>}
        </div>
        <DialogFooter className={"!justify-center !gap-2 p-5"}>
          <Button
            type="submit"
            size="sm"
            className="min-w-[100px] h-[34px]"
            onClick={onClickSave}
          >
            Save
          </Button>
          <Button
            type="submit"
            size="sm"
            className="min-w-[100px] h-[34px]"
            variant={"outline"}
            onClick={onHide}
          >
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateSentry;
{/* <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Display:
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <Select
                  onValueChange={(e) =>
                    onChangDisplayAnalysisOptions(JSON.parse(e))
                  }
                  defaultValue={formData.display}
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Date Set Price"
                    >
                      {formData.display !== null
                        ? formData.display.label
                        : "Select Date Set Price"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {displayAnalysisOptions && displayAnalysisOptions.length > 0 ? (
                        displayAnalysisOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div> */}
{/* <Select
                  onValueChange={(e) =>
                    onChangDateSetPriceOptions(JSON.parse(e))
                  }
                  defaultValue={formData.dataSet}
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Data"
                    >
                      {formData.dataSet !== null
                        ? formData.dataSet.label
                        : "Select Data"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {dateSetPriceOptions && dateSetPriceOptions.length > 0 ? (
                        dateSetPriceOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select> */}
{/* <Select
                  onValueChange={(e) => onChangAssetOptions(JSON.parse(e))}
                  defaultValue={
                    formData.assets !== null ? formData.assets : null
                  }
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Assets"
                    >
                      {formData.assets?.label
                        ? formData.assets?.label
                        : "Select Assets"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {assetOptions && assetOptions.length > 0 ? (
                        assetOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select> */}