import React from 'react'
import { Link } from 'react-router-dom'

const SimpleNewsItemLinkUi = (props) => {
    const {key, sectionName, webTitle, href} = props;
    return (
        <li className='border-b border-borderV1  group' key={key}>
            <Link to={href} target="_blank" className='block w-full py-3 px-2 group-hover:bg-[#d7ebff]'>
                <div className=''>
                    <h3 className='text-[13px] text-theme font-500 capitalize flex items-center gap-2 group-hover:ms-2 transition-all duration-300'>
                        <span>{sectionName}</span>
                        <span>|</span>
                        <span>{webTitle}</span>
                    </h3>
                </div>
            </Link>
        </li>
    )
}

export default SimpleNewsItemLinkUi