
import { Filters, apiBaseURL, subClassActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const fetchSubClass = (filter) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.SUB_CATEGORIES + `?page=${filter?.page ? filter?.page : ""}&perPage=${filter?.perPage ? filter?.perPage : ""}`);
    dispatch({ type: subClassActionType.FETCH_SUB_CLASSES, payload: response.data?.data });
    dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const addSubClass = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.SUB_CATEGORIES, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchSubClass(Filters.OBJ));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deleteSubClass = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.delete(apiBaseURL.SUB_CATEGORIES + "/" + id);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchSubClass(Filters.OBJ));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const fetchSubCategorie = (id, include) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));

    let subClassUrl = apiBaseURL.CATEGORIES + "/" + id + '/sub-class'
    if (include) {
      subClassUrl += `?type=${include}`
    }

    const response = await apiConfig.get(subClassUrl);
    dispatch({ type: subClassActionType.FETCH_SUB_CLASS, payload: response.data?.data });
    // dispatch(fetchSubClass())
    // dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updateSubClass = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.SUB_CATEGORIES + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchSubClass(Filters.OBJ));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};