import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Card, CardContent } from "@/components/ui/Card"
import Loading from '@/shared/common/Loading'
import LoadSaveAllocation from '../_components/loadSaveAllocation'
import TargetTypeRadioGroup from '../_components/target-type-radio-group'

const LoadAllocationTargetType = lazy(() => import('../_components/load-allocation-target-type'))

const LoadAllocation = (props) => {
    const {
        setTargetType,
        targetType,
        currentAllocationName,
        loadAllocationInfo,
        setLoadAllocationInfo,
        allAllocationsData,
        setAllAllocationsData,
        allocationTab,
        allPortfolios

    } = props;

    const [loadAllocationsError, setLoadAllocationsError] = useState({});

    useEffect(() => {
        setLoadAllocationsError({})
    }, [allocationTab]);

    useEffect(() => {
        setAllAllocationsData([])
    },[targetType])
    return (
        <div>
            <div className="container mx-auto py-4 px-0">
                <Card className="mb-8 bg-white">
                    <CardContent className="py-8">

                        <Suspense fallback={<div className='h-[200px] flex justify-center items-center '><Loading /></div>}>
                            <TargetTypeRadioGroup setTargetType={setTargetType} targetType={targetType} allocationTab={allocationTab} />
                        </Suspense>

                        <Suspense fallback={<div className='h-[200px] flex justify-center items-center '><Loading /></div>}>
                            <LoadAllocationTargetType setTargetType={setTargetType} targetType={targetType} loadAllocationInfo={loadAllocationInfo} setLoadAllocationInfo={setLoadAllocationInfo} loadAllocationsError={loadAllocationsError} setLoadAllocationsError={setLoadAllocationsError} setAllAllocationsData={setAllAllocationsData} allPortfolios={allPortfolios} />
                        </Suspense>

                        <LoadSaveAllocation currentAllocationName={currentAllocationName} allAllocationsData={allAllocationsData} setAllAllocationsData={setAllAllocationsData} loadAllocationInfo={loadAllocationInfo} setLoadAllocationInfo={setLoadAllocationInfo} loadAllocationsError={loadAllocationsError} setLoadAllocationsError={setLoadAllocationsError} targetType={targetType} />

                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default LoadAllocation