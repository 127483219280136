import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";


const TooltipProviders = (props) => {
  return (
    <TooltipProvider >
      <Tooltip  >
        <TooltipTrigger asChild>{props.children}</TooltipTrigger>
        <TooltipContent className="bg-white rounded-sm" side={props.direction || "right"}>
          <p>{props.tooltipMessage}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipProviders;