import Asset from "../pages/dashboard/Asset/Asset";
import Account from "../pages/dashboard/account/Account";
import Allocation from "../pages/dashboard/allocation/Allocation";
import AllocationDetails from "../pages/dashboard/allocation/AllocationDetails";
import Analytics from "../pages/dashboard/analytics/Analytics";
import BYOD from "../pages/dashboard/byod/BYOD";
import Data from "../pages/dashboard/data/Data";
import Issuer from "../pages/dashboard/issuer/Issuer";
import News from "../pages/dashboard/news/News";
import Portfolio from "../pages/dashboard/portfolio/Portfolio";
import PortfolioGroup from "../pages/dashboard/portfolioGroup/PortfolioGroup";
import Prices from "../pages/dashboard/prices/Prices";
import PricesDetailsPage from "../pages/dashboard/prices/details-page/PricesDetailsPage";
import Reporting from "../pages/dashboard/reporting/Reporting";
import ResearchNotes from "../pages/dashboard/researchNotes/ResearchNotes";
import Analysis from "../pages/dashboard/sentry copy/Analysis";
import Sentry from "../pages/dashboard/sentry/Sentry";
import Setting from "../pages/dashboard/setting/Setting";
import ViewDocument from "../shared/common/viewDocument/ViewDocument";

import AdminUser from "../pages/admin/User/AdminUser";
import AdminSettings from "../pages/admin/setting/AdminSettings";
import Profile from "../pages/dashboard/Profile";
import NewAllocation from "../pages/dashboard/new-allocation";
import DynamicAllocation from "../pages/dashboard/new-allocation/dynamic-allocation";
export const route = [
    // {
    //     path: "/portfolio-group/:slug",
    //     ele: <PortfolioGroup />,
    //     permission: 'user',
    // },
    // {
    //     path: "/portfolio/:slug",
    //     ele: <Portfolio />,
    //     permission: 'user',
    // },
    // {
    //     path: "/:slug",
    //     ele: <Portfolio />,
    //     permission: 'user',
    // },                               
    {
        path: "/portfolio/:slug",
        ele: <PortfolioGroup />,
        permission: 'user',
    },
    {
        path: "/portfolio/:slug/:slug",
        ele: <Portfolio />,
        permission: 'user',
    },
    {
        path: "/allocation",
        ele: <NewAllocation/>,
        permission: 'user',
    },
    {
        path: "/allocation/manage-allocation",
        ele: <DynamicAllocation />,
        permission: 'user',
    },
    // {
    //     path: "/allocation/:slug",
    //     ele: <AllocationDetails/>,
    //     permission: 'user',
    // },
    {
        path: "/reporting",
        ele: <Reporting />,
        permission: 'user',
    },
    {
        path: "/news",
        ele: <News />,
        permission: 'user',
    },
    {
        path: "/sentry",
        ele: <Sentry />,
        permission: 'user',
    },
    {
        path: "/sentry",
        ele: <Sentry />,
        permission: 'user',
    },
    {
        path: "/analysis",
        ele: <Analysis />,
        permission: 'user',
    },
    {
        path: "/research-notes",
        ele: <ResearchNotes />,
        permission: 'user',
    },
    // 
    {
        path: "/data",
        ele: <Data />,
        permission: 'user',
    },
    {
        path: "/data/asset",
        ele: <Asset />,
        permission: 'user',
    },
    {
        path: "/data/issuer",
        ele: <Issuer />,
        permission: 'user',
    },
    
    {
        path: "/data/analytics",
        ele: <Analytics />,
        permission: 'user',
    },
    {
        path: "/data/prices",
        ele: <Prices />,
        permission: 'user',
    },
    {
        path: "/data/prices/:slug",
        ele: <PricesDetailsPage />,
        permission: 'user',
    },
    {
        path: "/data/BYOD",
        ele: <BYOD />,
        permission: 'user',
    },
    {
        path: "/account",
        ele: <Account />,
        permission: 'user',
    },
    {
        path: "/account/setting",
        ele: <Setting />,
        permission: 'user',
    },
    {
        path: "/account/profile",
        ele: <Profile />,
        permission: 'user',
    },
    {
        path: "/view/file/:fileName",
        ele: <ViewDocument />,
        permission: 'user',
    },

    // admin Routes
    {
        path: "/admin/user",
        ele: <AdminUser />,
        permission: 'admin',
    },
    {
        path: "/admin/account/setting",
        ele: <AdminSettings />,
        permission: 'admin',
    },
]