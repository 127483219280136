import React, { Suspense, lazy } from 'react'
import { Card, CardContent } from "@/components/ui/Card"
import Loading from '@/shared/common/Loading'
import TargetTypeRadioGroup from '../_components/target-type-radio-group'

const TargetType = lazy(() => import('../_components/target-type'))

const CreateAllocation = (props) => {
    const {
        setTargetType,
        selectedPortfolioGroup,
        setSelectedPortfolioGroup,
        selectedPortfolio,
        targetType,
        setSelectedPortfolio,
        createAllocation,
        createAllocationInfo,
        setCreateAllocationInfo,
        createAllocationErrors,
        allPortfolios
    } = props;

    return (
        <div>
            <div className="container mx-auto py-4 px-0">
                <Card className="mb-8 bg-white">
                    <CardContent className="pt-8">
                        <TargetTypeRadioGroup setTargetType={setTargetType} targetType={targetType} />
                        <Suspense fallback={<div className='h-[400px] rounded-md bg-white flex justify-center items-center '><Loading /></div>}>
                            <TargetType setTargetType={setTargetType} selectedPortfolioGroup={selectedPortfolioGroup} setSelectedPortfolioGroup={setSelectedPortfolioGroup} selectedPortfolio={selectedPortfolio} setSelectedPortfolio={setSelectedPortfolio} targetType={targetType} createAllocation={createAllocation} createAllocationInfo={createAllocationInfo} setCreateAllocationInfo={setCreateAllocationInfo} createAllocationErrors={createAllocationErrors} allPortfolios={allPortfolios} />
                        </Suspense>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default CreateAllocation