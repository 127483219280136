import React from 'react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from "@/components/ui/label"
const TargetTypeRadioGroup = (props) => {
    const { setTargetType, targetType } = props;
    return (
        <div>
            <Label className="text-sm font-medium pb-3 block">Target Type</Label>
            <RadioGroup defaultValue={targetType} value={targetType} onValueChange={setTargetType} className="flex space-x-4 mb-4">
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="Portfolio Group" id="portfolio-group" className="cursor-pointer" radioClassName="fill-gray-700" />
                    <Label htmlFor="portfolio-group" className="cursor-pointer">Portfolio Group</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value="Portfolio" id="portfolio" className="cursor-pointer" radioClassName="fill-gray-700" />
                    <Label htmlFor="portfolio" className="cursor-pointer">Portfolio</Label>
                </div>
            </RadioGroup>
        </div>
    )
}

export default TargetTypeRadioGroup