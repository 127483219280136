import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import Creatable from "react-select/creatable";
import { fetchIssuer } from '../../../../store/actions/issuerAction'
import ResearchNoteSelect from '../selector/researchNoteSelect'
import ReSearchNoteAssetSelect from '../selector/ReSearchNoteAssetSelect'
import ReSearchNoteCustomSelect from '../selector/ReSearchCustomField'
import { AddReSearchNotes, EditReSearchNotes } from '../../../../store/actions/ResearchNoteAction'


const AddResearchNotesModal = (props) => {
    const { show, onHide, editData, setEditData } = props
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({
        _id: "",
        name: "",
        description: "",
        issuer: null,
        asset: null,
        customFields: null
    });

    useEffect(() => {
        if(show === false){
            setFormData({
                name: "",
                description: "",
                issuer: null,
                asset: null,
                customFields: null
            })
        }
    }, [show])

    useEffect(() => {
        if (editData?._id && show === true) {
            setFormData({
                _id: editData?._id,
                name: editData?.name,
                description: editData?.description,
                asset: { label: editData?.asset?.assetName || editData?.asset?.assetId, value: editData?.asset?._id },
                issuer: { label: editData?.issuer?.name, value: editData?.issuer?._id },
                customFields: editData?.customFields?.length > 0 && editData?.customFields?.map((cField) => {
                    return { label: cField.name, value: cField._id }
                })
            })
        } else {
            setFormData({
                _id: "",
                name: "",
                description: "",
                issuer: null,
                asset: null,
                customFields: null
            })
        }
    }, [show])

    const onSelectResearchNote = (value) => {
        setFormData({ ...formData, issuer: value, asset: null, customFields: null })
        // setAssetIssuerId(value)
    }
    const onSelectAssetResearchNote = (value) => {
        setFormData({ ...formData, asset: value, customFields: null })
        // setAssetIssuerId(value)
    }
    const onReSearchNoteCustomSelect = (value) => {

        setFormData({ ...formData, customFields: value })
        // setAssetIssuerId(value)
    }



    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.name === "") {
            errors['name'] = 'Please enter name';
            isValid = false;
        }
        // if (formData.description === '') {
        //     errors['description'] = 'Please enter description';
        //     isValid = false;
        // }
        if (formData.issuer === null && formData.asset === null) {
            errors['issuer'] = 'Please select issuer';
            isValid = false;
        }
        if (formData.asset === null && formData.issuer === null) {
            errors['asset'] = 'Please enter select asset';
            isValid = false;
        }
        // if (formData.customFields === null) {
        //     errors['customFields'] = 'Please select customFields';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };




    const onChangeData = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const onSave = () => {
        if (handleValidation()) {

            const customFieldsIds = formData?.customFields?.length > 0 && formData?.customFields?.map((notes, index) => {
                return notes?.value
            })

            // const { _id, customFields, ...another } = formData
            // const prepareData = {
            //     ...another, // Spread the properties of "another"
            //     customFields: customFieldsIds // Set the "customFields" property to the new value
            // };
            // customFieldsIds
            const { customFields, ...another } = formData
            const prepareData = {
                name: formData?.name,
                description: formData?.description,
                asset: formData?.asset?.value ? formData?.asset?.value : null,
                issuer: formData?.issuer?.value ? formData?.issuer?.value : null,
                // customFields: []
            };
            dispatch(AddReSearchNotes(prepareData))
            setFormData({
                name: "",
                description: "",
                issuer: null,
                asset: null,
                customFields: null
            })
            onHide()
        }
    }

    const onEditModel = () => {
        if (handleValidation()) {

            const customFieldsIds = formData?.customFields?.length > 0 && formData?.customFields?.map((notes, index) => {
                return notes?.value
            })
            // customFieldsIds
            const { customFields, ...another } = formData
            const prepareData = {
                _id: formData?._id,
                name: formData?.name,
                description: formData?.description,
                asset: formData?.asset?.value ? formData?.asset?.value : null,
                issuer: formData?.issuer?.value ? formData?.issuer?.value : null,
                // customFields: []
            };
            dispatch(EditReSearchNotes(prepareData))
            setFormData({
                _id: "",
                name: "",
                description: "",
                issuer: null,
                asset: null,
                customFields: null
            }) 
            onHide()
        }
    }

    return (
        <>
            <Dialog open={show} onOpenChange={onHide}>
                <DialogContent className="sm:max-w-[60%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{formData?._id ? "Edit Research Notes" : "Add Research Notes"}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-5">
                            <div className="flex flex-col gap-6">
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label
                                            htmlFor="name"
                                            className="text-[13px] text-textV2 font-500 cursor-pointer"
                                        >
                                            Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input className={`form-control shadow-none h-[34px] text-sm px-2  ${error['name'] && 'is-invalid'}`} placeholder="Name" autoComplete={'off'} name='name' onChange={(e) => onChangeData(e)}
                                            id="inputEmail3" type="text"
                                            value={formData.name}
                                        />
                                        <div className={error['name'] ? "text-red text-[10px]" : ''}>{error['name'] ? error['name'] : null}</div>
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Description:
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Textarea className={`form-control shadow-none h-[34px] text-sm px-2 ${error['description'] && 'is-invalid'}`} placeholder="description" name='description' onChange={(e) => onChangeData(e)} id="basic-form-textarea" rows="4"
                                            value={formData.description}
                                        />
                                        <div className={error['description'] ? "text-red text-[10px]" : ''}>{error['description'] ? error['description'] : null}</div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-col gap-6">
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Issuer:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <ResearchNoteSelect onSelectResearchNote={onSelectResearchNote} isDisabled={false} formData={formData} />
                                        <div className={error['issuer'] ? "text-red text-[10px]" : ''}>{error['issuer'] ? error['issuer'] : null}</div>
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Asset:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <ReSearchNoteAssetSelect onSelectAssetResearchNote={onSelectAssetResearchNote} isDisabled={false} formData={formData} />
                                        <div className={error['asset'] ? "text-red text-[10px]" : ''}>{error['asset'] ? error['asset'] : null}</div>
                                    </div>
                                </div>
                                {/* formData.type.label === 'Ordinal' && */}
                                {<div className="form-group grid grid-cols-12 items-center gap-1 hidden">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Custom Fields:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        {/* <Creatable
                                            className={`form-select rounded-sm  ${error["asset"] ? "is-invalid border-1" : "border-0"
                                                }  p-0`}
                                            classNamePrefix="select rounded-[12px]"
                                            placeholder="Select Custom Fields"
                                            onChange={(e) => {
                                                // 
                                                setFormData({...formData,customFields:e})
                                            }}
                                            name="All"
                                            // value={formData.portfolio !== null ? formData.assets : null}
                                            isMulti
                                            // onChange={onChangeAssetOption}
                                            options={dummyData}
                                            // components={{ GroupHeading }}
                                            styles={{
                                                groupHeading: (base) => ({
                                                    color: "black",
                                                }),
                                                menuList: (base) => ({
                                                    fontSize: "12px",
                                                    overflow: "auto",
                                                    maxHeight: "350px",
                                                }),
                                                option: (base) => ({
                                                    ...base,
                                                    cursor: "pointer",
                                                }),
                                            }}
                                        /> */}
                                        <ReSearchNoteCustomSelect onReSearchNoteCustomSelect={onReSearchNoteCustomSelect} isDisabled={(!formData?.asset && !formData?.issuer)} formData={formData} />
                                        <div className={error['customFields'] ? "text-red text-[10px]" : ''}>{error['customFields'] ? error['customFields'] : null}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        {/* formData.type.label === 'Ordinal' && */}
                        {<div className="grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1">
                            {/* <div className="my-2 gap-0">
                                <Button type="submit" size="sm" variant='outline' className="min-w-[100px]"
                                //  disabled={formData.shortName === '' || formData.type === null || isValid} onClick={() => setShowModel(!showModel)}
                                >Add Value</Button>
                            </div> */}
                            <div className="flex flex-col gap-0">
                                {/* <DataTableWithCustomPagination
                                    data={paginatedData}
                                    columns={columns}
                                    isSearchHide={true}
                                    hidePagination={true}
                                    isOldHeaderHide={true}
                                    isRightNew={true}
                                    isCollViewHide={true}
                                    setPerPage={setPerPage}
                                    perPage={perPage}
                                    totalRecords={data?.length || 0}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={Math.ceil((data?.length || 0) / perPage)} /> */}
                            </div>
                        </div>}
                        {/* {formData.type.label === 'Ordinal' && <CreateValueModel
                            show={showModel}
                            onHide={() => setShowModel(false)}
                            updatedId={updatedId}
                            shortCodeName={formData.shortName}
                            data={data}
                            setData={setData}
                            addDataIds={addDataIds}
                            setAddDataIds={setAddDataIds}
                            fetchData={fetchData} />}
                        {formData.type.label === 'Categorical' && <div className="grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1">
                            <div className="my-2 gap-0">
                                <Button type="submit" size="sm" variant='outline' className="min-w-[100px]" disabled={formData.shortName === '' || formData.type === null || isValid} onClick={() => setShowModel(!showModel)}>Add Value</Button>
                            </div>
                            <div className="flex flex-col gap-0">
                                <DataTableWithCustomPagination
                                    data={paginatedData}
                                    columns={columnsCategorical}
                                    isSearchHide={true}
                                    hidePagination={true}
                                    isOldHeaderHide={true}
                                    isRightNew={true}
                                    isCollViewHide={true}
                                    setPerPage={setPerPage}
                                    perPage={perPage}
                                    totalRecords={data?.length || 0}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={Math.ceil((data?.length || 0) / perPage)} />
                            </div>
                        </div>}
                        {formData.type.label === 'Categorical' && <CreateCategoricalModel
                            show={showModel}
                            onHide={() => setShowModel(false)}
                            updatedId={updatedId}
                            shortCodeName={formData.shortName}
                            data={data}
                            setData={setData}
                            addDataIds={addDataIds}
                            setAddDataIds={setAddDataIds}
                            fetchData={fetchData} />} */}
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        {formData?._id ? <Button type="submit" size="sm" className="min-w-[100px]"
                            onClick={onEditModel}
                        >Save</Button> : <Button type="submit" size="sm" className="min-w-[100px]"
                            onClick={onSave}
                        >Add</Button>}
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"}
                            onClick={onHide}
                        >Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddResearchNotesModal
