import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import toastReducer from './toastReducer';
import portfolioReducer from './portfolioReducer';
import PortfolioGroupReducer from './portfolioGroupReducer';
import metaDataReducer from './metaDataReducer';
import classReducer from './classReducer';
import subClassReducer from './subClassReducer';
import typeReducer from './typeReducer';
import subTypeReducer from './subTypeReducer';
import StrategyReducer from './StrategyReducer';
import PortfolioGroupDetailsReducer from './PortfolioGroupDetailsReducer';
import allPortfolioReducer from './allPortfoliosReducer';
import portfoliosColumnReducer from './portfoliosColumnReducer';
import updateStateReducer from './updateSateReducer';
import InvestedAssetReducer from './portfolioTransactionReducer';
import analyticsReducer from './analyticsReducer';
import portfolioTransactionHistoryReducer from './portfolioTransactionHistoryReducer';
import fetchPortfolioGroupInvestedAssetColumnReducer from './fetchPortfolioGroupInvestedAssetColumnReducer';
import assetsDetailsReducer from './AssetDetailsReducer';
import ColumnsViewsReducer from './ColumnsViewsReducer';
import ColumnsViewDetailsReducer from './ColumnsViewDetailsReducer';
import CustomColumnsViewReducer from './CustomColumnsViewReducer';
import reportsReducer from './reportsReducer';
import chartDataReducer from './chartDataReducer';
import analyticReportsReducer from './analyticReportsReducer';
import userAnalyticsReducer from './userAnalyticsReducer';
import allocationsReducer from './AllocationsReducer';
import AllocationHistoryReducer from './AllocationHistoryReducer';
import AllocationsMonitorReducer from './AllocationsMonitorReducer';
import PortfolioGroupAllocationColumnReducer from './PortfolioGroupAllocationColumnReducer';
import portfolioAllocationColumnReducer from './portfolioAllocationColumnReducer';
import countriesReducer from './countriesReducer';
import assetsDetailsV2Reducer from './AssetReducer';
import financeExchangesReducer from './FinanceReducer';
import financeSearchReducer from './SearchFinancialAssetReducer';
import assetsAuthorizeV2Reducer from './AssetAuthorizeReducer';
import assetsHistoryReducer from './assetsHistoryReducer';
import financePriceReducer from './PricesReducer';
import BYODReducer from './BYODReducer';
import BYODValuesReducer from './BYODValuesReducer';
import newsReducer from './newsReducer';
import issuerReducer from './issuerReducer';
import issuerHistoryReducer from './issuerHistoryReducer';
import SentryReducer from './SentryReducer';
import updateCustomFieldDataReducer from './updateCustomFieldDataReducer';
import researchReducers from './ReSearchNoteReducers';
import researchNoteContentReducers from './ReSearchNoteReducers';
import assetByIssuerReducer from './assetByIssuerReducer';
import researchNotesContentTagReducer from './researchNotesContentTagReducer';
import viewDocFilesReducer from './viewDocFilesReducer';
import saleTransactionReducer from './saleTransactionReducer';
import adminUserReducer from './admin/userReducer';
import adminTypeReducer from './admin/adminTypeReducer';
import adminSubTypeReducer from './admin/adminSubTypeReducer';
import adminClassReducer from './admin/adminClassReducer';
import adminSubClassReducer from './admin/adminSubClassReducer';
import curveReducer from './curveReducer';
import tenorReducer from './tenorReducer';
import userReducer from './userReducer';
import allocationsV2Reducer from './allocationV2Reducer';

const allReducers = combineReducers({
    //define all your reducers
    // loginUser: authReducer,
    isLoading: loadingReducer,
    toast: toastReducer,
    metaData: metaDataReducer,
    portfolios: portfolioReducer,
    PortfolioGroup: PortfolioGroupReducer,
    classData: classReducer,
    subClassData: subClassReducer,
    types: typeReducer,
    subTypes: subTypeReducer,
    strategy: StrategyReducer,
    PortfolioGroupDetails: PortfolioGroupDetailsReducer,
    allPortfolio: allPortfolioReducer,
    portfoliosColumn: portfoliosColumnReducer,
    updateState: updateStateReducer,
    investedAsset: InvestedAssetReducer,
    analytics: analyticsReducer,
    investedAssetHistory: portfolioTransactionHistoryReducer,
    PortfolioGroupColumnsViews: fetchPortfolioGroupInvestedAssetColumnReducer,
    assetsDetails: assetsDetailsReducer,
    assetsV2: assetsDetailsV2Reducer,
    columnsViews: ColumnsViewsReducer,
    columnsViewDetails: ColumnsViewDetailsReducer,
    customColumnsView: CustomColumnsViewReducer,
    reports: reportsReducer,
    reportsData: chartDataReducer,
    analyticReports: analyticReportsReducer,
    userAnalytic: userAnalyticsReducer,
    allocation: allocationsReducer,
    allocationHistory: AllocationHistoryReducer,
    AllocationsMonitor: AllocationsMonitorReducer,
    PortfolioGroupAllocationColumnsViews: PortfolioGroupAllocationColumnReducer,
    allocationColumnViews: portfolioAllocationColumnReducer,
    countries: countriesReducer,
    financeExchange: financeExchangesReducer,
    financeSearchData: financeSearchReducer,
    assetsAuthorize: assetsAuthorizeV2Reducer,
    assetsHistory: assetsHistoryReducer,
    financePrice: financePriceReducer,
    BYODData: BYODReducer,
    BYODDataValues: BYODValuesReducer,
    customValueData: updateCustomFieldDataReducer,
    news: newsReducer,
    issuerData: issuerReducer,
    issuerHistory: issuerHistoryReducer,
    sentryData: SentryReducer,
    researchNotes: researchReducers,
    researchNoteContent: researchNoteContentReducers,
    assetByIssuer: assetByIssuerReducer,
    researchNotesTag: researchNotesContentTagReducer,
    displayDoc: viewDocFilesReducer,
    saleTransaction: saleTransactionReducer,
    curve: curveReducer,
    tenor: tenorReducer,
    user_profile: userReducer,
    allocationV2: allocationsV2Reducer,
    // admin 
    user: adminUserReducer,
    adminTypes: adminTypeReducer,
    adminSubTypes: adminSubTypeReducer,
    adminClass: adminClassReducer,
    adminSubClass: adminSubClassReducer,
})

export default allReducers;