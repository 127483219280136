import apiConfig from '../../config/apiConfig';
import { allocationsV2ActionType, apiBaseURL, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { addTost } from './toastAction';

export const AddAllocationV2 = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let url = `${apiBaseURL.ALLOCATION}/assets-allocation-v2`;

        const response = await apiConfig.post(url, payload);
        dispatch({ type: allocationsV2ActionType.ADD_ALLOCATION_V2, payload: response.data?.data });
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(setLoading(false))
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}
export const getAllocatedPortfolios = (allocationId) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let url = `${apiBaseURL.ALLOCATION}/${allocationId}/portfolios`;

        const response = await apiConfig.get(url);
        dispatch({ type: allocationsV2ActionType.GET_ALLOCATED_PORTFOLIOS_V2, payload: response.data?.data });
        dispatch(setLoading(false))
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const getAllocatedAuthorizedAssets = (allocationId) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let url = `${apiBaseURL.ALLOCATION}/${allocationId}/authorized-assets`;

        const response = await apiConfig.get(url);
        dispatch({ type: allocationsV2ActionType.GET_ALLOCATED_AUTHORIZED_ASSETS_V2, payload: response?.data?.data });
        dispatch(setLoading(false))
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const removeAssetAllocation = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let url = `${apiBaseURL.ALLOCATION}/remove-assets-allocation`;

        const response = await apiConfig.delete(url, payload);
        dispatch({ type: allocationsV2ActionType.DELETE_ASSET_ALLOCATIONS, payload: response?.data?.data });
        dispatch(setLoading(false))
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const lockUnlockAssetAllocation = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let url = `${apiBaseURL.ALLOCATION}/assets-allocation/lock-unlock`;

        const response = await apiConfig.post(url, payload);
        dispatch({ type: allocationsV2ActionType.LOCK_UNLOCK_ASSET_ALLOCATION, payload: response.data?.data });
        dispatch(setLoading(false))
        return response;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}