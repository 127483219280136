import React, { useState } from "react"
import { Button } from "@/components/ui/button"
import { Download } from "lucide-react"
import { useNavigate } from "react-router-dom"
import CustomCreatableSelect from "@/components/common/customCreatableSelect"
import { useDispatch } from "react-redux"
import { fetchAllocationsDetails } from "@/store/actions/AllocationAction"
import { IconLoading } from "@/shared/common/Loading"

const LoadSaveAllocation = (props) => {
  const { allAllocationsData, setAllAllocationsData, loadAllocationInfo, setLoadAllocationInfo, loadAllocationsError, setLoadAllocationsError, targetType } = props; // savedAllocations
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadAllocationLoading, setLoadAllocationLoading] = useState(false);


  const loadAllocationOptions = allAllocationsData?.length > 0 ? allAllocationsData.map((allocation) => ({
    value: allocation._id,
    label: allocation.name,
    rest: { ...allocation },
  })) : [];


  const loadAllocationValidation = () => {
    let isValid = true;
    let error = {};

    if (loadAllocationInfo?.loadAllocation === null) {
      error.loadAllocation = "Please select a Allocation";
      isValid = false;
    }

    if (loadAllocationInfo?.portfolioGroup === null) {
      error.portfolioGroup = "Please select a Portfolio Group";
      isValid = false;
    }
    
    if (loadAllocationInfo?.portfolio === null && targetType === 'Portfolio') {
      error.portfolio = "Please select a Portfolio";
      isValid = false;
    }

    setLoadAllocationsError(error);
    return isValid;
  }

  const handleLoadAllocation = () => {
    if (loadAllocationValidation()) {
      dispatch(fetchAllocationsDetails(loadAllocationInfo?.loadAllocation?.value, null, null, setLoadAllocationLoading)).then(res => {
        if (res?.data?.success) {
          localStorage.setItem('currentAllocationDetails', JSON.stringify(res?.data?.data));
          navigate("/allocation/manage-allocation")
        }
      });

      localStorage.setItem("currentAllocation", JSON.stringify(loadAllocationInfo.loadAllocation))
    }
  }

  const handleChangeLoadAllocation = (selectedOption) => {
    setLoadAllocationInfo({
      ...loadAllocationInfo,
      loadAllocation: selectedOption,
    })
  }

  return (
    <div>
      <h3 className="text-lg font-semibold py-2">Load Allocations</h3>
      <div className="grid grid-cols-1 gap-2">
        <div className="flex items-center space-x-2">
          <div className="w-full">
            <CustomCreatableSelect
              options={loadAllocationOptions}
              classNamePrefix={'react_select'}
              className='text-[12px] w-full'
              onChange={handleChangeLoadAllocation}
              value={loadAllocationInfo?.loadAllocation}
              placeholder="Select a Load Allocation"
              styles={{
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? "#4ea3fd" : "white",
                  cursor: "pointer",
                  borderBottom: "1px solid #f4f4f5",
                }),

              }}
            />

          </div>
          <Button onClick={handleLoadAllocation} className="w-[100px] !bg-theme">
            {loadAllocationLoading ? <IconLoading height={15} width={15} /> : <Download className="w-4 h-4 mr-2" />}
            Load
          </Button>
        </div>
      </div>
      <div className={loadAllocationsError['loadAllocation'] ? "text-red text-[12px]" : ''}>{loadAllocationsError['loadAllocation'] ? loadAllocationsError['loadAllocation'] : null}</div>
    </div>
  )
}

export default LoadSaveAllocation